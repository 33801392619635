@import "./layout/color";

@import "./pages/login";


// @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "GE-BodyCopy", sans-serif;
  box-sizing: border-box;
}

.app-content {
  max-height: 100%;
  max-width: 100%;
}

.app-content1 {
  width: 100%;
}

.app-content-mob {
  padding: 1rem;
  height: calc(100vh - 17.5rem);
}

.modal-header {
  font: "GE-BodyCopy",sans-serif;
  font-size: 14px;
}

.Modal.Title {
  font: "GE-BodyCopy",sans-serif;
}

.action-area {
  background-color: #eff3fc;
  padding: 8px 0.5rem 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  flex-flow: row;
}

.action-area1 {
  background-color: white;
  height: 3rem;
}

.action-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  border: none;
  text-decoration: none;
  border-radius: 0.25rem;
}

.btn-text {
  font-size: 12px;
  line-height: 1;
  text-align: left;
  font-weight: 600;
  margin-right: 1.25rem;
}

.reset-filter-text {
  font-size: 12px;
  line-height: 1;
  text-align: left;
  font-weight: 600;
  margin-right: 1.25rem;
  background-color: #DEDFE0;
  border-radius: 1vw;
  padding: 0.6vw;
}

.btn-text1 {
  font-size: 9px;
  line-height: 1;
  text-align: left;
  font-weight: 600;
  margin-right: 1.25rem;
}

.btn-text2 {
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: 600;
}

.btn-text-cm {
  font-size: 18px;
  line-height: 1;
  text-align: left;
  font-weight: 600;
}

.reset-btn {
  margin-left: 0.5rem;
  outline: none;
  border: none;
  background-color: #ffffff;
  color: #264ae4;
  font-size: 0.875rem;
}

.modaladd {
  background-color: #264ae4;
  width: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: 1px solid;
  text-decoration: none;
  border-radius: 0.25rem;
  color: #fff;
  border-color: #0d6efd;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
}

.modal :is(.form-control):focus {
  background-color: white;
}

.modal-footer-country {
  justify-content: flex-start !important;
  background-color: #fff;
  bottom: -5rem;
  background: #FFF;
  width: 100%;
  margin-bottom: 1rem;
}

.modal-footer {
  background-color: #fff;
}

.modal-footer-report {
  background-color: #eff3fc;
}

.confirmcss {
  align-items: center;
  text-align: center;
  font-weight: normal;
  color: #6F6F70;
}

.status-color {
  width: 0.5rem;
  min-width: 0.5rem;
  // height: 100%;
  height: 48px;
  border-radius: 5px 0px 0px 5px;
  margin-right: 0.5rem;
}

.status-color-mob {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  margin-right: 0.5rem;
}

.bread-crumb {
  height: 2rem;
  background-color: #dfe5f8;
  padding: 0.5rem 1rem;

}

.breadcrumb-item a {
  text-decoration: none !important;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 920px !important;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-size: 16px;
  font-family: 'GE-BodyCopyHighlight';
  color: var(--txt-color);
}

/* Global Search */
.global-search-container {
  //margin: 0 1rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.global-search-box {
  //padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
}

.global-search-box>div {
  border: 1px solid #a7aec6;
  border-radius: 4px;
  
}

.global-search-box input {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  // transition: 0.5s ease;
  width: 85%;
}

.global-search-box.show input {
  flex-grow: 1;
  display: block;
  padding: 0 1rem;
}

.global-search-box input:placeholder-shown {
  display: inline-block;
    width: 85%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.global-search-box ::placeholder {
  color: #000000;
  font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
}

.pagintion-bar {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 5px;
  height: 1rem;
  font-weight: 500;
  font-size: 14px;
  color: #525252;
  padding-bottom: 1.5rem;
}

.pagintion-bar svg {
  cursor: pointer;
  color: #525252;
}

.pagintion-bar * {
  margin-right: 12px;
}

.pagintion-bar .disabled {
  pointer-events: none;
  color: #b5b9c6;
}

.header .dropdown-menu {
  right: 5px;
  top: 40px;
}


.no1req-input {
  color: #a0a0a0;
  font-size: 10px;
  font-weight: 500;
  padding-top: 0%;
  padding-bottom: 0%;
}

.form-control-padding {
  padding: 0.2rem 0.75rem;
  background-color: #E2E2E2;
}

.form-control1-padding {
  padding: 0.2rem 0.75rem;
  background-color: #E2E2E2;
}


.form-control1:disabled,
.form-control1[readonly] {
  background-color: #F8F7D5;
  opacity: 1;
}

.form-control1 {
  background-color: #EAEEFF;
}

.form-control2-padding {
  padding: 0.2rem 0.75rem;
  background-color: #E2E2E2;
}

.form-control2:disabled,
.form-control2[readonly] {
  background-color: #7F86A0;
  opacity: 1;
}

.form-control2 {
  background-color: #7F86A0;
}

.place-center-eq {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.place-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.place-center1 {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding-bottom: 10px;
}

.place-right {
  display: flex !important;
  // align-items: center;
  // justify-content: space-evenly;
}

.place-end {
  display: flex !important;
  justify-content: end;
}

.statusCell {
  color: $aemGreen !important;
  font-weight: 800;
}

.tableCellGreen {
  color: $aemGreen !important;
}

.tableCellGrey {
  color: $aemGrey !important;
}

.ag-row:hover {
  border: none !important;
}

.ag-row {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
    font-size: 16px !important;
    font-weight: 600;
    color: #030911;
  }
}



///testing added 0n 30thOct 2023

/* WCS Global Search */
.wcs-search-container {
  //margin: 0 1rem;
  display: flex;
  align-items: center;
  flex-grow: 1;

  // align-items: right;
  //flex-grow: 1;
  // position: relative;
  //   left: 140px;
}

.wcs-search-box {
  //padding: 0.5rem 1rem;
  width: 100%;
  //display: flex;
  // flex-flow: row;
  // justify-content: flex-end;
  // align-items: center;
}

.wcs-search-box>div {
  border: 1px solid #a7aec6;
  border-radius: 4px;
  //width: 45%;
}

.wcs-search-box input {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  // transition: 0.5s ease;
  width: 85%;
}

.wcs-search-box.show input {
  flex-grow: 1;
  display: block;
  padding: 0 1rem;
}

.wcs-search-box input:placeholder-shown {
  display: inline-block;
    width: 25%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.wcs-search-box ::placeholder {
  color: var(--txt-color);
  font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
}