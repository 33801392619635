body {
  margin: 0;
  font-family: "GE-BodyCopy", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --godrej-red: #E1142E;  /* #E1142E */
  --godrej-red-alt: #EA2F27;  /* #EA2F27 */
  --godrej-red-bg: #FAE8E6;
  --godrej-green: #33C037;  /* #33C037 */
  --godrej-green-alt: #16911B;  /* #16911B */
  --godrej-green-bg: #E6FAEE;
  --godrej-orange: #FF7715;  /* #FF7715 */
  --godrej-orange-bg: #FFEECE;
  --godrej-blue: #00B5D6;  /* #00B5D6 */
  --godrej-blue-bg: #E7EFFA;
  --godrej-yellow: #FFA814;  /* #FFA814 */
  --godrej-purple: #810055;  /* #810055 */
  --table-bg: #F9F2F6;  /* #F9F2F6 */
  --bg-alt: #FAE9E8;
  --inactive-gray: #B0B2B6;
  --bg-alt-2: #F1F1ED;
  --col-ddd: #ddd;
  --col-707070: #707070;
  --txt-color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GE-BodyCopy';
  src: url('./Assests/font/fonts-rebranding/GE-BodyCopy.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GE-BodyCopyItalic';
  src: url('./Assests/font/fonts-rebranding/GE-BodyCopyItalic.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-BodyCopyItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'GE-BodyCopyHighlight';
  src: url('./Assests/font/fonts-rebranding/GE-BodyCopyHighlight.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-BodyCopyHighlight.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GE-BodyCopyHighlightItalic';
  src: url('./Assests/font/fonts-rebranding/GE-BodyCopyHighlightItalic.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-BodyCopyHighlightItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'GE-Headline';
  src: url('./Assests/font/fonts-rebranding/GE-Headline.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-Headline.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GE-HeadlineItalic';
  src: url('./Assests/font/fonts-rebranding/GE-HeadlineItalic.woff2') format('woff2'),
       url("./Assests/font/fonts-rebranding/GE-HeadlineItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}