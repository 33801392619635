
.ag-header-cell{
  padding-left: 22px !important;
  padding-right: 10px !important;
}
 .ag-cell-inline-editing  {
 
   position: absolute;

padding-bottom: 0px !important;
padding-left: 0px !important;
padding-right: 0px !important;
padding-top: 2px !important;
  border-radius: 4px !important;

  /* width: 8% !important; */
  height: 70% !important;
  vertical-align: top;
  background-color: transparent;
} 

.ag-cell-edit-wrapper{
  
  padding: 0%;
  /* background-color: #E36334; */
  vertical-align: middle;
}

.ag-picker-field-wrapper {
  background-color: transparent !important;
  border: none !important
}

.dot {
    height: 5px;
   width: 5px;
  background-color: var(--col-ddd);
   border-radius: 50%;
    display: inline-block;
    }
  
    /* .avatarFirst {
      background-color: #d5e6f5;
      }

  .orangeAvatar {
      color:#2D48DB;
  }

  .purpleAvatar {
      color: #5CA58C;
  } */
.Hyperlink{
  color: var(--godrej-purple);
}
.EditableText{
  z-index: 4;
  border: 1px solid var(--godrej-purple);
  border-Radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  width: 95% !important;
  outline: none;
  line-height: 2% !important;
}
.btn-add-custom{
  background: var(--godrej-purple);
}
  
    
    .input::placeholder {
      
      color: var(--col-707070);
  }

.ag-theme-material .ag-has-focus .ag-cell-inline-editing {
    --ag-input-focus-border-color: var(--godrej-purple) !important 
}

.ag-theme-material .ag-has-focus .ag-cell-focus {
  border: none !important;
}

.ag-theme-material .ag-standard-button {
  color: var(--txt-color) !important;
}

    /* .ag-cell{
      display: flex !important;
      align-items: center !important;
    } */

    .ag-header-cell-text {
      text-align: left;
      font: "GE-BodyCopy", sans-serif;
      font-size: 14px;
      letter-spacing: 0px;
      color: var(--txt-color);
      opacity: 1;
      font-weight: 500;
    }
  
    .ag-header-cell, .ag-header-group-cell {
      padding-left: 2px;
      padding-right: 2px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  
    .ag-cell-value {
      text-align: left;
      font: "GE-BodyCopy", sans-serif;
      font-size: 14px;
      /* font-size: 12px; */
      letter-spacing: 0px;
      color: var(--col-707070);
      opacity: 1;
    }
  
    .avatarFirst {
      background-color: var(--godrej-blue-bg);
      color: var(--godrej-blue); 
      
  }

  .orangeAvatar {
      color: var(--godrej-orange);
      background-color: var(--godrej-orange-bg);
     
  }

  .purpleAvatar {
      color: var(--godrej-green);
      background-color: var(--godrej-green-bg);
      
  }

    .StatusActiveInactive {

      border-top-left-radius:3px;  
      border-top-right-radius:3px;  
      border-bottom-right-radius:3px;  
      border-bottom-left-radius:3px; 
    }
  
    .form-field{
      width: 278px;
    }

    .toast-message {
      color: #fff;
      font-size: 14px;
      right: 34vw;
      height: 0.5vw;
      width: 95vw;
      margin-top: 3vw;
      padding: 10px 5px;
  }

 
  
    .active-color{
      background: #E9FAE9 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    .reset-custom{
        top: 454px;
        left: 347px;
        width: 109px;
        height: 40px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid var(--godrej-purple);
        color: var(--godrej-purple);
        opacity: 1;
        border-top-left-radius:5px;  
    border-top-right-radius:5px;  
    border-bottom-right-radius:5px;  
    border-bottom-left-radius:5px; 
      }
    
      .ag-header-cell-text {
        color: black;
    }
    
     
    
    
    .profile-initials1 {
      width: 40px;
      height: 40px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 1em;
      
      -webkit-font-smoothing: antialiased;
      position: absolute;
      color: #fff;
      background: var(--bg-alt);
      border-radius: 50%;
      
      text-align: center;
      left: 8%;
      top: 4px !important;
      margin: 0 4 0 -15px;
      font-size: 12px;
      color: var(--godrej-orange);
      text-align: center;
    }    



  .a {
    color:var(--godrej-orange);
      }

  .b {
      color: var(--godrej-blue);
  }

  .c {
    color:var(--godrej-green);
  }
.ag-header-active {
  --ag-header-cell-hover-background-color: var(--table-bg) !important;
}
.ag-row-selected::before {
  --ag-selected-row-background-color: var(--table-bg) !important;
}
.ag-row-hover::before {
  --ag-row-hover-color: var(--table-bg) !important;
}
@media (max-width: 767px) {
.title{
  margin-top: 0px !important;
  padding-top: 0px !important;
  position: relative;
  top:0.1cm;
}

.add_btn {
  position: relative;
  /* left:55%; */
  margin-top: 10px;
}

.ListTitle{
  position: relative;
  left:15%;
}

.global-search-box > div{
  font-size: 12px;
  border: 1px solid #a7aec6;
  border-radius: 4px;
  width: 90% !important;
}

#breadCrumbsMobile{
  display: block !important;
}

.bread-crumb{
  display: inline !important;
}

.scrollable-container {
  max-height: 65vh !important;
  overflow-y: scroll !important;
  padding-bottom: 20%;
}


.formobile{
  position: relative;
  top: 30px
}

.link-button {
  font-size: 14px !important;
position: relative;
left:50% !important;
top: 55%;
  background: none;
  border: none;
  color: blue; 
  text-decoration: underline; 
  cursor: pointer;
  /* margin-bottom:  10px !important; */
  font: inherit;
}

.link-button:hover {
  text-decoration: none; 
}

img, svg {
  vertical-align: middle;
  height: 0.7cm;
}
.colHeader{
  font-weight: bold;
  padding-left: 10% !important;
  padding-right: 5% !important;
  padding-top: 1px !important;
  
}

.colData{
 
  padding-left: 10% !important;
  padding-right: 15% !important;
  padding-top: 2px !important;
  
}
  }
