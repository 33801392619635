@import "../layout/color";

.login-container {
  // overflow: hidden;
  // width: 100%;
  height: 100vh;
  .navbar {
    .container {
      flex-direction: row;
      align-items: center;
      .login-text {
        font-size: 12px;
      }
    }
  }
}
.login-text {
  color: var(--godrej-purple);
  font-family: "GE-Headline", sans-serif;
  margin: 0;
}
.rightSideLoginPage {
  .login-text {
    font-size: 56px;
    padding-top: 5rem;
  }
}
.place-right{
  display: flex;
}
.LoginRightRadioDiv {
  display: flex;
  gap: 20px;
  .LoginRightRadioDivTitle {
    color: $aemGrey;
    font-size: 14px;
    font-weight: 500;
  }
  .LoginRightRadioDivTitleBlue {
    color: $aemBlue;
    font-size: 14px;
    font-weight: 500;
  }
  .LoginRightRadioLabel {
    font-weight: 600;
  }
  .form-check-label {
    font-size: 14px;
  }
}
.LoginRightInput {
  text-align: center;
  
  .form-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .inputPassword {
    position: relative;
    .input-group-text {
      padding: 0px;
      img {
        width: 36px;
      }
    }
    .inputPasswordEyeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 5px;
    }
  }
  .loginButton {
    width: 100%;
    background-color: $aemLogin;
    border: 1px solid $aemLogin;
  }
  .forgotButton {
    font-weight: 600;
    width: 90%;
    height: 56px;
    // background: #3339CD 0% 0% no-repeat padding-box;
  }
  .disabledButton {
    background-color: $aemGrey;
    border: 1px solid $aemGrey;
  }
}
.loginLeftImage img {
  // margin-top: 3%;
  // width: 233%;
  // height: 100%;
  // object-fit: contain;
  // overflow: hidden;
  // right: -50%;
  // position: absolute;

}
// .loginLeftImage {
//   position: relative;
//   height: calc(100vh - 17px);
//   margin-left: 40%;
//   // padding: 5%;
// }
.loginLeftImage {
  // height: calc(100vh - 17px);
  height: calc(100vh - 59px);
  max-width: 60%;

  // margin-left: 40%;
  // padding: 5%;

}
.LoginForgotTitle {
  font-size: 18px;
  font-weight: 600;
}
.loginImgRight1 {
  width: 15%;
}
.loginImgRight2 {
  width: 38%;
}
.forgotText {
  cursor: pointer;
  // right: 0;
  color: $aemBlue;
  font-size: 14px;
  font-weight: 500;
  //margin-left: 250px;
  position: absolute;
  //padding-right: 1rem;
}

.forgotModalText {
  cursor: pointer;
  right: 0;
  position: absolute;
  padding-right: 3rem;
}
.loginModal {
  justify-content: flex-start;
}
.loginErrorDiv {
  background: $aemLightRed;
  color: $aemRed;
  border-radius: 5px;
  text-align: left;
  padding: 0.8rem;
  font-size: 14px;
  .loginErrorDivHeader {
    font-weight: 600;
  }
}
.forgotPasswordModal {
  .modal-body {
    text-align: center;
    .modalHeading {
      font-weight: 600;
      margin: 20px 0px 10px 0px;
    }
    .modalText {
      font-size: 14px;
      padding: 0 5rem 0 5rem; 
      color: $aemGrey;
    }
  }
}
.alertBoxLogin {
  position: fixed;
  z-index: 100;
  width: -webkit-fill-available;
  margin: 15px 15px 0px 15px;
}

.textright {
  text-align: right;
  color: $aemGrey;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 767px) {
  .forgotText {
    cursor: pointer;
    // right: 0;font-size: 14px;
    color: $aemBlue;
    font-size: 14px;
    font-weight: 500;
    //margin-left: 235px;
    position: absolute;
    //padding-right: 1rem;
  }
}

@media screen and (max-width: 600px) {

.place-right{
  position: relative;
  // right: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;

  // top:10%;
  // width:fit-content;
  // overflow: hidden;
  
}

.loginLeftImage {
  // height: calc(100vh - 17px);
  // height: calc(100vh - 0px);
  height: 0%;
  max-width: none;


  // margin-left: 40%;
  // padding: 5%;

}

// .login-container {
//   position: relative;
//   right: 12%;
//   width: 100%;
//   // top:20%;


// }
.LoginRightInput{
  width: 100%;
}

.LoginRightInput .forgotButton {
    // width: 80%;
    position: relative;
    bottom: 50px;
}

.LoginRightImageAem{
  width: 100%;
  
}

.LoginRightImageGodrej{
  height: 5%;
}



}
