.css-1xhypcz-MuiStack-root {
    padding-top: 3px;
}

.divFilter {
    padding-top: 1rem;
}

.btn-outline-success {
    --bs-btn-color: var(--godrej-purple);
    --bs-btn-border-color: var(--godrej-purple);
    --bs-btn-active-bg: var(--godrej-purple);
    --bs-btn-active-border-color: var(--godrej-purple);
}

@media (max-height: 850px) {
    .divFilter {
        max-height: 600px;
        overflow-y: auto !important;
        padding-bottom: 150px;
    }
}