@import "../../../../Assests/font/fontGE.css";
.WPSMainPDF{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
       font-size: 7.6px;
    padding-top: 0;
    background-color: white;
    letter-spacing:0.01px;
    width: 793px;
    height: 830px;
  
}


.WPScontainer{

    display: flex;
    height: 100%;
}


.WPSgroup{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
    font-size: 7.6px;
    display: flex;
    /* align-items: left; */
 
}
.WPSflexb{
    display: flex;
    flex-direction: column;
   
    align-items: stretch;
}
.WPSheaderRow{
    order: 1;
    flex-grow: 0;
    padding-top: 1px;
}
.WPSContent{
    order: 2;
  
    flex-grow: 1;
   
}
.WPSfooterRow{
   order: 3;
   align-items: flex-end;
   flex-grow: 0;
   /* position: relative;
   top: 100px; */
   /* position: absolute;
   bottom: 10px; */
}

.WPSvl{
    border-left: 1px solid black;

}
.WPSnoborder{
    border-bottom: none;
    border-top: none;
    border-left: none;
    background-color: white;
}
.WPSnoborder1st{
    border-bottom: none;
    border-top: none;
    border-left: none;
    background-color: white;
    /* width: 200px !important; */
}
.WPSSignatureTable{
    font-family: "GE-BodyCopyHighlight";
    font-size:  9px;
    font-weight:900;
    font-style:bold;
}
.WPSfooter{
    

    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    font: "GE-BodyCopy";
       font-size:  11px;
    font-weight: bold;
    table-layout: fixed;
    width: 570px;
    /* width: 71%; */
}
.WPSfooter td{
    

    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: .1px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: right;
    font: "GE-BodyCopy";
        font-size:  10px;
    
}

.WPSTable1{
    width: 72%;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    padding-top: 0%;
    padding-bottom: 0%;
    border:none;
 }

 .WPSTable1 td{

    font-family: "GE-BodyCopy";
    font-style: normal;
        font-size: 7.6px;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 3px;


}

.WPSTable1 th{
    
    text-align: left;
    background: var(--col-ddd);
    
    font-family: "GE-BodyCopy";
    font-style:normal;

    font-weight:normal;

        font-size: 7.6px;
    width: 20%;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
  padding-left: 3px;
}


.WPStablenoborder{
    font-family: "GE-BodyCopyHighlight";
    font:"GE-BodyCopyHighlight";
    font-weight:900;
    font-style: italic;
    width: 620px;
    border: none;
    padding: 0;
    vertical-align: top;
    padding: 0%;
    
}
/* .WPStablenoborder td th{
    font:"GE-BodyCopyHighlight";
    font-family: "GE-BodyCopyHighlight";
    font-weight:900;
    font-weight: bold;
    font-size: 7.6px;
    padding-left: 3%;
    padding-top: 0%;
    padding-bottom: 0%;

    vertical-align: top;
    height: .05px;
} */
.WPSgodrejTitle{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size:  12px;
    font-weight: bold;
    letter-spacing:0.00;
    padding:0;
    text-align: center;
}
.WPSgodrejAddress{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size:  11px;
    padding: 0;
}
.WPSpageTitle{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size:  18px;
    font-style: italic;
    font-weight: bolder;
    padding: 0;
}

.WPQGOpageTitle{
    font-family: "GE-BodyCopyHighlightItalic";
    font: "GE-BodyCopyHighlightItalic";
    font-size:  12px;
    font-style: italic;
    font-weight: 900;
    padding-left: 0px;
    padding-right: 10px;
    position: relative;
    right: 61px;
    bottom: 5px;
}
.fontGE{
    font-family: "GE-BodyCopyHighlightItalic";
    font: "GE-BodyCopyHighlightItalic";
    font-size:  13px;
    font-style: italic;
    font-weight: bolder;
}
.WPSpageHeaderTitle{
    font-family: "GE-BodyCopyHighlightItalic";
    font: "GE-BodyCopyHighlightItalic";
    font-size:  13px;
    font-style: italic;
    font-weight: bolder;
    padding-bottom: 5px;
    padding-right: 10px;
    position: relative;

}

.PQRpageTitle{
    /* font-family: "GE-BodyCopyHighlightItalic";
    font:"GE-BodyCopyHighlightItalic";
    font-weight:900;
    font-style: bold;
    font-size:  13px;
    padding-bottom: 5px;
    right: 0px;
    position: relative;
    bottom: 5.1px; */
    font-family: "GE-BodyCopyHighlightItalic";
    font: "GE-BodyCopyHighlightItalic";
    font-size:  13px;
    font-style: italic;
    font-weight: bolder;
    padding-bottom: 5px;
    /* padding-right: 16px; */
    right: 0px;
    position: relative;
}


.WPSright-td-border{
    border-bottom: solid black;

}

 .WPSbasemetal{
     font-family: "GE-BodyCopyHighlight";
     font: "GE-BodyCopyHighlight";
     font-weight:900;
     font-style: bold;
           font-size: 8.5px;
     padding-top: 0%;
     width: 20% ;
     padding-bottom: .2%;
     line-height: 1;
}
 .WPSPthickness {
     text-align: left;
     width: 18.5%;
     font-family: "GE-BodyCopyHighlight";
     font: "GE-BodyCopyHighlight";
     font-weight:900;
         font-size: 8.5px;
     align-items: left;
     padding-left: 2.5px;
     letter-spacing:0.00px;
     padding-top: 0%;
     padding-bottom: .2%;
     line-height:1;

 
}
.WPSempty{
    width: 1%;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
.WPSemptyTitle{
 
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 8px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
 .WPSmesursment {
    /* padding-left: 5px; */
     font-family: "GE-BodyCopyHighlight";
     font: "GE-BodyCopyHighlight";
     text-align: left;
            font-size: 8.5px;
     padding-bottom: 0%;
     padding-top: 0%;
     line-height: 1;
   }

 .WPStitle5 {
     font-family: "GE-BodyCopyHighlight";
     font: "GE-BodyCopyHighlight";
    text-align: left;     
           font-size: 8.5px;
    text-align: right;
    font-style: normal;
    font-weight: bold;
    padding-top: .1%;
    padding-bottom: 0.3%;
    line-height: 1;
    }
.WPStitle4{
        font-family: "GE-BodyCopy";
        font: "GE-BodyCopy";
        padding-left: 2px;
       text-align: right;     
              font-size: 7.6px;
       text-align: right;
       padding-top: .1%;

       padding-bottom: 0%;
       line-height: 1;
       }

.WPSTable2{
    width: 640px;
    border: none;
    font-family: "GE-BodyCopy";
    font-style: normal;
    padding: 0;
}

.WPSTable2 td
{
    padding-left: 3px;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    border: none;
           font-size: 7.6px;
    font-style: normal;
 
    vertical-align: top;
    height: auto;
    /* width: 10%; */
      
}

.WPSTable2 th{
   
    text-align: left;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    border:none;

    vertical-align: top;
    font-weight:normal;

           font-size: 7.6px;
    padding-left: 3px;
    /* width: 300px !important; */
}
.WPStable2bottomboder{
    border-bottom:  none;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
    border-bottom: .1px solid black;
    
}
.WPStable2rightborder{
    border-right: .13px solid black;
    border-top: .15px solid black;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
    }
.WPStable2withbottomboder{
    border-bottom:  .11px solid black;
}
.WPStable2firstcolumn{
    /* width: 50% !important; */
    /* width: 200px !important; */
    border-right: .12px solid black;
    border-top: .14px solid black;
    border-left: .14px solid black;
    border-bottom: none;
    font: "GE-BodyCopy";
           font-size: 7.6px;
}
/* .widthth{
    width: 205px !important;

} */
/* .widththpno{
    width: 30px !important;
text-align: center !important;
} */

/* .widththHtcon{
    width: 40px !important;
    text-align: center;
} */

.widththGrpno{
    width: 30px !important;
    text-align: center;
}

.widthblank{
    width: 100px !important;
}

.widthth3table{
    width: 12.5% !important;

}

.WPSTable3{
    font-family: "GE-BodyCopy";
    width: 85%;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.WPSmin{
           
    text-align: center;
    font:  "GE-BodyCopy";
}
.WPSmax{

    text-align: center;
      
}

.WPStable-3 {
    width: 45%;
    border: none;
    font-family: "GE-BodyCopy";
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    
}
.WPStable-3 th{
    font-family: "GE-BodyCopy";
    padding: .1px;
    border-collapse:collapse;
    font: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    width: 60%;

}    
.WPStable-3 td{
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
    border: none;
    border-top: none;
    border-bottom: none;
    text-align: center;
    
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPStable3-th{
    text-align: left;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
    background: var(--col-ddd);
    border-top: none;
    border-bottom: none;
    border-right:  .13px solid black;
    border-left: .13px solid black;
    border-top: .1px solid black;
    width: 50%;
}
.WPStable3-th-left{
    text-align: left;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
    background: var(--col-ddd);
    border-top: none;
    border-bottom: none;
    border-right:  .11px solid black;
    border-left: .11px solid black;
}
.WPStable3-th{
    font-family: "GE-BodyCopy";
}
.WPStable3-thwithboder{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    background: var(--col-ddd);
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: .11px solid black;

}
.borderBottom{
    border-bottom: 1px solid black;
}
.borderTop{
    border-top: 1px solid black;
}
.WPStable3-thwithbtboder{
    text-align: left;
    font-weight: normal;
    /* background: #CCCCCC; */
    background: var(--col-ddd);
    font-family: "GE-BodyCopy";
    font-style: normal;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: .11px solid black;
    border-left: .11px solid black;
           font-size: 7.6px;
  
}
.WPStable3-topth{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .1px solid black;
    border-left: .11px solid black;
    text-align: center;
           font-size: 7.6px;
  
}
.WPStable3-topth1{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: .1px solid black;
    text-align: center;
           font-size: 7.6px;
  
}
.WPStable3-bottomth{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    text-align: center;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: .1px solid black  ;
    border-left: none;
           font-size: 7.6px;
  
}
.WPStable3-sideth{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    text-align: center;
    border-left: .1px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
  
}
.WPStable3-sideth1{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    text-align: center;
    border-left: .11px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
    
  
}
.WPStable3-siderth{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    font-family: "GE-BodyCopy";
    font-style: normal;
    text-align: center;
    border-right: .11px solid black;
    border-top: none;
    border-left: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
  
}
.WPSt3-td-text{
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPStable-3-tdborder{
   font-family: "GE-BodyCopy";
   border-bottom: .11px solid black;
}

.WPStable-4{
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    
    table-layout: fixed;
}
.WPStable-4 td th{
    border: .1px solid black;
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPSleft-td{
    border-top: .1px solid black;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
    
}
.WPSleft-td-nobottom{
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: none;
    border-top: none;
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSTable2Td{
    width: 2%;
     border:  .1px solid black;
    border-bottom: .1px solid black;
    border-left: .1px solid black
}

.WPSleft-td-nobottomnoleft{
    border-left: none;
    border-right: .1px solid black;
    /* border-bottom: .1px solid black; */
    border-top: none;
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSNoLeft{
    border-left: none;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    border-top: none;
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSleft-td-nobottomtop{
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-bottom: none;
    border-top: none;
    border-top: .1px solid black;
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSright-td{
    border-top: .11px solid black;
    border-left: .11px solid black;
    border-right: .11px solid black;
    
}
.WPSright-td1{

    border-right: .11px solid black;
    width: 1%;
}
.WPSBMTable6 {
    border: none;
    /* width: 72.6%;    */
    width: 640px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    font-family: "GE-BodyCopy";
    font-style: normal;
    height: auto;
}

.WPSBMTable td{
   
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPSBMTable6 th{padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    /* font: "GE-BodyCopy"; */
    font-style: normal;
           font-size: 7.6px;
  
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPSTable6 {
    border: none;
    /* width: 72.6%;    */
    width: 570px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.WPSTable6 td{
   
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPSTable6 th{padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    /* font: "GE-BodyCopy"; */
    font-style: normal;
           font-size: 7.6px;
  
    padding-top: 0%;
    padding-bottom: 0%;
}
.WPSleft-th-nobottomnoright{
    border-right: none;
    border-top: none;
    border-left: .1px solid black;
    border-bottom   : .1px solid black;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 7.6px;

}

.WPSleft-th-nobottom{
    border-left: .1px solid black;
    border-right: none;
    border-top: none;
    border-bottom: none;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;

}
.WPSleft-th-nobottomflux{
    border-left: .1px solid black;
    border-right: none;
    border-top: .1px solid black;
    border-bottom: none;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;

}
.WPSleft-th-notopnoleft{
    border-left: none;
    border-top: none;
    border-right: .1px solid black;
    background-color: var(--col-ddd);
    border-bottom: .1px solid black;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;

}
.WPSleft-th-notop{
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-top: none;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;

}
.WPSleft-th{
    border-top: .1px solid black;
   
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSforfont{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPSright-th{
    border-top: .1px solid black;
    border-left: .1px solid black;
    border-right: .1px solid black;
     background: var(--col-ddd);
    font-family: "GE-BodyCopy";
           font-size: 7.6px;
    border-collapse: collapse;
}
.WPSTable7{
    
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
    font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;

   /* width: 72.6%; */
   width: 570px;
   
}
.WPSTable7 th{padding-left: 3px;
text-align: left;
background: var(--col-ddd);
padding-top: 0%;
border-right: .1px solid black; 

}
.WPSTable7 td{
    
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    white-space: nowrap;
}



.WPStdhidden{
    visibility: hidden;
    width: 0%;
    border: none;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
}
.WPStrhide{
    border-style: hidden;
    border-right: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
    font-style: normal;
}    
.WPStrFirstRow{
    border-style: hidden;
    border-right: .1px solid black;
    border-top: .1px solid black;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
    font-style: normal;
}    

.WPStrLastRow{
    border-style: hidden;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
    font-style: normal;
}    
.WPSside{
    width: 25%;
}

.WPSthhideFirstRow{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-top: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 30%;
}
.WPSthhideLastRow{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-top: none;
    border-bottom: .1px solid black;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 30%;
}

.WPSthhide{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-top: none;
    border-bottom: none;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 30%;
}

.WPSthhide1{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-top: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;

}
.WPSthhidelast{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-bottom: .1px solid black;
    border-top: none;
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    
}
.WPSleft7-td-noleft{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    
    border-top: none;
    border-left: none;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 7.6px;   
}


.WPSleft7-td-noright1{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
   
    border-top: none;
   border-right: none;
   font-family: "GE-BodyCopy";
   font: "GE-BodyCopy";
          font-size: 7.6px;
   border-left: none;
   z-index: 10;
}
.WPSleft7-td-noright{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;

    border-top: none;
   border-right: none;
   font-family: "GE-BodyCopy";
   font: "GE-BodyCopy";
          font-size: 7.6px;
   border-left: .1px dashed black;
   z-index: 10;

}
.WPSleft7-td{
    border-right: .1px dashed black;
    border-left: .1px dashed black;
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;

    border-top: none;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
           font-size: 7.6px;
           z-index: 10;


   
}

.WPSrcorner1{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
     float: right;
    text-align: right; 
    /* padding-right:2px; */
    width: 30px;
}
.WPSrcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
     float: right;
    text-align: right; 
    padding-right:2px;
}
.WPSwithLeftrcorner1{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: right;
    text-align: right;
    width: 43px;


}
.WPSwithoutLeftrcorner1{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: right;
    text-align: right;
    padding-left: 20px;
 width: 40px;
    

}
.WPSMidwithoutLefttext1{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 65px;
    width: 130px;
}
.WPSMidwithLefttext1{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 4px;
    width: 70px;   
}
.WPSwithLeftrcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: right;
    text-align: right;
    /* padding-right: 2px; */
    /* padding-left: 0px; */
    width: 70px;


}
.WPSwithoutLeftrcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: right;
    text-align: right;
    /* padding-right: 2px; */
    padding-left: 40px;

    

}
.WPSmid{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 2px;

}
.WPSmidGas{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 2px;
    text-align: center;
}
.WPSMidwithLefttext{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 8px;
    width: 51px;   
}
.WPSMidwithoutLefttext{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    padding-left: 45px;
    width: 100px;
}

.WPStdlcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: left;

    padding-left: 0%;
    width: 200px;
    text-align: center;
}
.WPStdrcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: right;
    width: 200px;
    padding-left: 0%;
    text-align: center;
}
.WPSlcorner{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: left;
    padding-left: 0%;
 
}
.WPSgroupcols{
    display: flex;
    align-self: center;
    align-items: center;
}
.WPSlcorner3cols{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
       font-size: .5px;
    float: left;
    word-wrap: break-word;
    text-align: center;

}

.WPSlcorner3colstube{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float: left;
    word-wrap: break-word;
    text-align: center;
    position: relative;
    right: 30px;

}
.WPSrcorner3cols{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
       font-size: .5px;
    float:right;
    text-align: center;
    word-wrap: break-word;
    position: relative;
    left: 10px;

    /* align-items: center; */

}
.WPSrcorner3colstube{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
    float:right;
    text-align: center;
    word-wrap: break-word;
    /* position: relative;
    right: 90px; */

    /* align-items: center; */

}

.Noteswrap{
    word-wrap: break-word !important;
    width: 100px !important;
    padding-left: 3px;
}

.WPSmid3cols{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
       font-size: .5px;
       text-align: center;
       word-wrap: break-word;
       position: relative;
       left: 6px;

       /* text-align: right; */


}

.WPSmid3colstube{
    font-family: "GE-BodyCopy";
    font:  "GE-BodyCopy";
           font-size: 7.6px;
       text-align: center;
       word-wrap: break-word;
       position: relative;
       left: 180px;
       width: 100px;

       /* text-align: right; */


}
.WPSTable7 tr:nth-child(even) {
    background-color:var(--bg-alt-2);
    /* z-index: 10; */ 
    
}

.processleft1{
    background-color: transparent;
    background: none;
}
.WPSTable7 tr:nth-child(odd) {
    background-color: white;
    
    
}



.WPSborderhidden{
    border-bottom-style: hidden;
}

.WPSimgsize{
    position: absolute;

margin-left: 2.5%;   
margin-top: 1px;
 overflow-wrap: break-word;
    z-index: 0;


}

.WPSimgsizebig{
    /* margin-left: 3%;   
    margin-top: 1px; */
    width: 100%;
    height: 100%;
     overflow-wrap: break-word;
        z-index: 0;


}
.WPSTable8{
    /* width: 72.6%; */
    width: 570px;
    border-collapse:collapse;
    border: "1px solid black";
    
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    
}

.WPSTable8 td{
    
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    
}
.WPSTable8 th{
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border-bottom:  1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
           font-size: 7.6px;
    /* width: 18%; */
}
.WPSTable9 {
    border: none;
    width: 570px;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.WPSTable9 td{
    border-top: none;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-bottom: none;
}
.WPSTable9 th{
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    
    font-family: "GE-BodyCopy";
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border-top: none;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: none;
        font-size: 7.6px;
    width: 25%;
}

.WPSTable10 {
    border: .1px solid black;
    /* width: 72.6%;    */
width:570px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    font-family: "GE-BodyCopy";
        font-size: 7.6px;
        height: 170px;
        /* word-wrap: break-word; */
  
}
.WPSTable10 td{
    
    font-family: "GE-BodyCopy";
    font-style: normal;
           font-size: 7.6px;
       vertical-align: top;
   
}
.WPSTable11 {
    
    border: none;
    width: 570px;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}
.WPSTable11 td{
    border-top:  .1px solid black;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-collapse:collapse;
    font-family: "GE-BodyCopy";
    font-weight:900;
    font-style: bold;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-bottom:  .1px solid black;
}
.WPSTable11 th{
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    
    font-family: "GE-BodyCopy";
    font-style:normal;
    border-collapse:collapse;
    font-weight:900;
    font-style: bold;
    border-top:  .1px solid black;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
        font-size: 7.6px;
    width: 20%;
}

.WPSTable12 {
    font-family: "GE-BodyCopyHighlight";
    font: "GE-BodyCopyHighlight";
    font-weight: 900;
    font-style: bold;
    font-size: 7.6px;
    width: 72%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.WPSTable12 td{
    /* border-top:  .1px solid black; */
    border-left: .1px dashed black;
    border-collapse:collapse;
    font-family: "GE-BodyCopyHighlight";
    font: "GE-BodyCopyHighlight";
    font-weight: 900;
    font-style: bold;
    font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    /* border-bottom:  .1px solid black; */
    vertical-align: top;
    align-content:center;
    height: 10px;
}
.WPSimgsizebig{
    height: 95%;
    width: 95%
    
}

.process3{
    width: 200px;
    text-align: center;
}

.process2{
    width: 200px;
    text-align: center;
    /* background: "blue"; */
}
.process1{
    width: 200px;
    text-align: center;
    /* background: "black"; */
}

.processdatatube{
    text-align: center;
}

.insideprocess{
width: 400px;
/* background-color: blue; */
}

.insideprocess1{
width: 200px;
/* background-color: rebeccapurple; */
    }

.insideprocess2{
width: 200px;
/* background-color: red; */
        }

#data1 {
position: relative;
left: 13%;
 }



