.sideopen {
  width: 250px;
  border-right-style: solid;
  border-right-color: var(--bg-alt-2);
  height: 100%;
    overflow-y: hidden;
  overflow-x: hidden;
}

.sideclose {
  width: 100%;
  height: 100%;
  border-right-style: solid;
  border-right-color: var(--bg-alt-2);
  overflow-y: hidden;
  overflow-x: hidden;
}
.sidebar {
  width: 100%;
  height: 100%;
  border-right-style: solid;
  border-right-color: var(--bg-alt-2);
  border-right-width: 1px;
}
.menu-icons {
  display: inline-block;
  /* padding-bottom: 10px;
    padding-left: 12px; */
  padding: 10px 10px 6px 10px;
  vertical-align: middle;
}

.menu-toggleicon {
  display: inline-block;
  /* padding-bottom: 10px;
    padding-left: 12px; */
  padding: 7px 6px 6px 0px;
  vertical-align: middle;
  cursor: pointer;
}
.menu-gicons {
  display: inline-block;
  position: fixed;
  width: 45px;
  bottom: 3%;
  padding-left: 1%;
  vertical-align: bottom;
  z-index: 10;
}

.menu-gicons1 {
  display: inline-block;
  padding-left: 30%;
  padding-bottom: 2%;
  vertical-align: bottom;
  z-index: -9;
}

/* .menu-gicons1 {
  position: relative;
  left: 35%;
  top:20px;
  z-index: -9;
} */

.divImg {
  border-right: 3px solid var(--godrej-purple);
  background-color: var(--table-bg);
  display: flex;
  width: 100%;
}

.nav-children {
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--godrej-purple);
  opacity: 1;
  text-decoration: none;
  display: flex;
  display: inline-block;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-top: 0px;
  vertical-align: middle;
}
.nav-heading {
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
  display: flex;
}

nav a.active {
  color: var(--godrej-purple);
}

.top_section {
  height: 26px;
}

.menu-items {
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 14px;
  display: inline-block;
  /* padding-left: 8px;
    padding-bottom: 10px;
    padding-top: 2%; */
  padding: 6px 10px 8px 0px;
  color: var(--txt-color);
  vertical-align: middle;
  text-decoration: none;
}

.menu-activeitems {
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 14px;
  display: inline-block;
  /* padding-left: 8px;
    padding-bottom: 10px; */
  vertical-align: text-top;
  padding-top: 2%;
  color: var(--godrej-purple) !important;
  font-weight: bold;
  text-decoration: none;
}

.menu-items:hover{
  text-decoration: none;
  color: var(--godrej-purple) !important;
  /* color: black !important; */
  font-weight: bold;

}


.link {
  text-decoration: none;
  /* color: #3339cd !important; */
  color: black !important;

}

.link:hover{
  text-decoration: none;
  color: var(--godrej-purple) !important;
  /* color: black !important; */
  font-weight: bold;

}

.link:active{
  text-decoration: none;
  color: var(--godrej-purple) !important;
  /* color: black !important; */
  font-weight: bold;

}

.menu-toplogo {
  display: inline-block;
  padding-bottom: 10px;
  margin-left: 10%;
}

.menu-mobile {
  display: none;
}

.ImageMenu {
  display: block;
}

.menu-group{
  display: flex;
  flex-direction: row;
  
  width: 100%;

}
.menu-side-image{
  order: 1;
  width: 15%;

}
.menu-side-name{
  order: 2;
  width: 75%; 
  vertical-align: text-top  ;
  padding-left: 8px;
}
.menu-toggle-open{
  order: 3;
  width: 10%;
  vertical-align: middle;
}


.sideopen:hover{
  overflow-y: auto;
  
}

.sideopen:hover::-webkit-scrollbar{
  width: 6px;
}

.sideopen::-webkit-scrollbar-track{
  background: transparent;
  width: 20px;
}

.sideopen::-webkit-scrollbar-thumb{
  background-color: var(--col-ddd);
  border-radius: 3px;
}




.sideclose:hover{
  overflow-y: auto;
  
}

.sideclose:hover::-webkit-scrollbar{
  width: 6px;
}

.sideclose::-webkit-scrollbar-track{
  background: transparent;
}

.sideclose::-webkit-scrollbar-thumb{
  background-color: var(--col-ddd);
  border-radius: 3px;
}

@media screen and (max-width: 600px) {
  .sideopen {
    width: 250px;

    border-right-style: none;

    padding-top: 6%;

    z-index: 0;
    background-color: white;
    opacity: 1;
  }
  .profileimage {
    position: relative;

    padding-left: 1%;
    width: 100%;
    height: 30%;
    order: 1;
    padding-top: 0%;
    flex-grow: 2;
  }
  .profilemobile {
    position: relative;
    padding-left: 1%;
    width: 100%;
    height: 80%;
    order: 1;
  }
  .sidebar {
    width: 100%;
    height: 100%;
    z-index: 4;
    border-right-style: none;
  }

  .sideclose {
    width: 0%;
    border-right-style: none;
  }

  .menu-mobile {
    display: flex;
    flex-direction: column;
    padding-bottom: 1%;
    padding-left: 2%;
    cursor: pointer;
    height: 20%;
    width: 100%;
    border-bottom: var(--bg-alt-2);
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-right-style: none;
    opacity: 1;
    background-color: white;
  }

  .menu-mobile-close {
    display: block;
    padding-bottom: 1%;
    padding-left: 2%;
    cursor: pointer;
  }
  .menuclose {
    margin-left: 10%;
    margin-top: 0%;
    font: 'GE-BodyCopy',sans-serif, Regular;
    width: 70%;
    position: relative;
    align-content: center;
    z-index: 1;
  }
  .menu-toplogo {
    display: none;
  }
  .menu-gicons {
    display: none;
  }

  .ImageMenu {
    display: none;
  }

}
