.Main{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: row;
}

body {
    margin: auto;
    padding: 0;
    font-family: sans-serif;
}

h1,
h2 {
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
    
}

.ag-row-hover .cell-action {

    visibility: visible;

}

.cell-action {

    visibility: hidden;

}


/* .ag-row-hover>div {
    font-weight: bolder;
    font-size: 14px;
    color: black;
} 

.ag-row-hover>div span {
    font-weight: bolder;
    font-size: 14px;
    color: black;
}  */

/* Green checkmark radio button css - start */
.container input:checked {
  opacity: 0;
}

.container input{
  position: absolute;
  top: 34%;
}

.greenTick{
  display: none;
}

.container input:checked ~ .greenTick {
  display: block !important;
}
/* Green checkmark radio button css - end */


/* added to change hover colour of button */



.ag-root-wrapper-body.ag-layout-normal {
  flex: 1 1 auto;
  height: fit-content !important;
  min-height: fit-content !important;
}

