@import "./styles/index.scss";

.savebtn {
  z-index: 6;
  position: relative;
  bottom: 150px;
  left: 92%;

}

.trainingsavebtn {
  z-index: 6;
  position: relative;
  left: 85%;

}

.css-1xhypcz-MuiStack-root {
  display: flex !important;
  flex-direction: column !important;
}



.tempSaveBtn {
  width: 109px;
  height: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-color: var(--godrej-purple);
  color: var(--godrej-purple);
}

.rightAlignedButton {
  float: right !important;
}

.leftAlignedButton {
  float: left !important;
}

.AllUpdateScorebtn {
  z-index: 6;
  position: relative;
  bottom: 150px;
  left: 82%;
}

.ScoreUpdate {

  position: relative;
  left: 80px !important;
  justify-content: space-evenly !important;
}

.traineecell {
  position: relative !important;
  right: 20% !important;
}

.TrainNo {
  border: 1px solid var(--godrej-purple) !important;
  position: relative !important;
  margin-bottom: 50px !important;
  margin-left: 50px !important;
  font-size: 12px !important;
  padding: 4px 40px !important;
}

.TrainAdd {
  border: 1px solid var(--godrej-purple) !important;
  color: white !important;
  background-color: var(--godrej-purple) !important;
  font-size: 12px !important;
  position: relative !important;
  margin-bottom: 50px !important;
  margin-right: 100px !important;
  padding: 4px 40px !important;
}

.DialogTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
  position: relative !important;
  right: 140px;
  margin-bottom: 20px !important;
}

.DialogContext {
  font-size: 14px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
}


body {
  background: #FBFDFF
}




.accordion-button {
  background: #dfe5f8 0% 0% no-repeat padding-box;
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  width: 98%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;

  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button::after {
  margin-left: 2rem !important;
  color: var(--godrej-purple) !important;
  display: none;
}



.italic {
  font: italic normal normal 12px/16px "GE-BodyCopy",sans-serif;
}

.certifont {
  font-family: 'GE-BodyCopy' !important;
}

#weldAp {
  position: relative;
  left: 217px;
}

.joinTable {
  width: 100%;
}

#data {
  position: relative;
  left: 17%;
}

#weldpdata2 {
  text-align: center !important;
  justify-content: inherit !important;
  position: relative;
  left: 30%;
}

#weldpdata {
  position: relative !important;
  left: 2.85cm !important;
}

#weldpdata4 {
  position: relative !important;
  left: 1cm !important;
}

@media (max-width: 767px) {

  .left43 {
    left: 7.6% !important;
  }


  .deviationheaderResp {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .toast-message {
    position: absolute;
    top: 3%;
    left: 10%;
    width: 75% !important;
    height: 1%;
    opacity: 1;
  }

}










.remarkLabel {
  text-align: left;
  font: normal normal 600 16px/24px GE-BodyCopy,sans-serif;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

.accordion-header {
  border-radius: 8px;
  opacity: 1;
}



.profile-initials {
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  background: white;
  border-radius: 50%;
  text-align: center;
  margin: 0 0 0 -35px;
  font-size: 15px;
  color: var(--godrej-purple);
  text-align: center;
}

.profile-initialsClosure {
  width: 32px;
  height: 32px;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 33px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: var(--godrej-orange);
  background: var(--bg-alt);
  border-radius: 50%;
  text-align: center;
  margin: 0 0 0 -35px;
  font-size: 12px;
  text-align: center;
}



/*Training*/
.blue-arrow {
  padding-top: 2%;
  margin-left: 5%;
  margin-bottom: 1%;
}

.blue-arrow.downarrow {
  float: right;
  margin: -2% 0 0 3%;
}

.blue-arrow.uparrow {
  margin: -2% 0 0 3%;
}


.rag-amber {
  background-color: var(--godrej-red-bg);
}

.show-block {
  display: block;
}



.trainingcancelbtn {
  border: 1px solid var(--godrej-purple);
  border-radius: 6px;
  line-height: 30px;
  z-index: 6;
  background-color: white;
  color: var(--godrej-purple);

}

.cancelbtn {
  border: 1px solid var(--godrej-purple);
  border-radius: 6px;
  line-height: 30px;
  z-index: 6;
  background-color: white;
  color: var(--godrej-purple);
  position: relative;
  bottom: 150px;
  left: 72%;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--godrej-purple) !important;
}

.ag-theme-material {
  width: 1200px;
  height: 200px;
}

.gx-2 {
  margin-bottom: 2rem;
}

.front {
  position: relative;
  right: 20px;
}

.g-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.start-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.frm-label {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.T-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
}


.Date-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
}

.c-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
  width: 600;
}


.gpno-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
}

.green-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
}


.signature-box {
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.imgsizebig1 {
  width: 30%;
  height: 30%;

}

.spinner-box {

  display: grid;

  position: fixed;

  z-index: 1060;

  inset: 0;

  background: rgba(59, 63, 74, 0.85);
}

.shift {
  position: relative;
  top: 5px;
  left: 30px;
}

.gridloader {
  position: relative;
  top: 200px;
  left: 500px;
  z-index: 5;
}

.gridimgloader {
  position: relative;
  bottom: 10px;
  z-index: 5;

}

.gridimgdownloadloader {
  position: relative;
  top: 3px;
  z-index: 5;



}

.uploading {
  position: relative;
  bottom: 13px;
  z-index: 5;
  font-size: 11px;

}

.uploadingpdfname {
  position: relative;
  bottom: 3px;
  z-index: 5;

}

.loader {
  display: block;
  position: relative;
  bottom: 10px;
  z-index: 5;
}

.visible {
  display: block;
  position: relative;
  bottom: 10px;
  z-index: 5;
}

.hidden {
  display: none;
  position: relative;
  bottom: 10px;
  z-index: 5;
}

.imgbox {
  text-align: center;
}

.g-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 13px;
  left: 7px;
  cursor: text;
}


.start-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: absolute;
  top: 11px;
  left: 7px;
  cursor: text;
}

.T-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  padding: 3px;
  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 11px;
  left: 7px;
  cursor: text;

}


.Date-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  font-weight: 500;
  font-size: 12px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  transform: translateX(10px) translateY(-4px);
  color: var(--col-707070);

  cursor: text;
}

.gpno-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: text;
}

.pno-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: text;
}

.green-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-weight: 500;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: text;
}

.m-input span {
  font-family: "GE-BodyCopy",sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--col-707070);
}

.disabled {
  pointer-events: none;
  color: gray;
}

.autocomplete {
  width: 278px;
  height: 49px;
}


.borderhidden {
  border-bottom-style: hidden;
}

.g-input input,
.g-input select,
.g-input textarea {
  color: var(--txt-color);
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  box-sizing: border-box;
  height: 45px !important;
  padding-left: 6px !important;
}


.start-input input,
.start-input select,
.start-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 6px !important;
}


.T-input input,
.T-input select,
.T-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  padding-left: 6px !important;
}

.Date-input input,
.Date-input select,
.Date-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}


.allow {
  width: 270px;
}
.g-input > .allow {
  width: 100%;
}

.c-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-weight: bold;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: text;
}

.c-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  height: 2%;
  width: 125%;
  box-sizing: border-box;
}

.gpno-input input,
.gpno-input select,
.gpno-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 6px !important;
}


.pno-input input,
.pno-input select,
.pno-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 6px !important;
}


.green-input input,
.green-input select,
.green-input textarea {
  border: 1px solid var(--godrej-green-alt);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 6px !important;
}

.g-input.fill {
  display: block;
  width: 100%;
}

.start-input.fill {
  display: block;
  width: 100%;
}

.T-input.fill {
  display: block;
  width: 100%;
}


.search-select {
  padding-bottom: 3%;
}
.Supervised_remark {
  padding-top: 3%;
}

.pno-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-90%);
  font-size: 12px;
  color: var(--godrej-green);
}

.pro-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-90%);
  font-size: 12px;
  color: var(--col-707070);
  font-weight: 100;
}

.ListW-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-87%);
  font-size: 12px;
  color: var(--col-707070);
}

.promd4-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-77%);
  font-size: 12px;
  color: var(--col-707070);
  font-weight: 100;
}





.groove_datecolor {
  background: white;

}



.pno-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  font-weight: 500;

  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: relative;
  bottom: 43px;
  left: 5px;
  cursor: text;
}


.pro-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  font-weight: 500;

  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: relative;
  bottom: 40px;
  left: 5px;
  cursor: text;
}


.ListW-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: relative;
  bottom: 45px;
  left: 5px;
  cursor: text;
}

.promd4-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  font-weight: 500;

  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: relative;
  bottom: 40px;
  left: 5px;
  cursor: text;
}



.normal-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  font-weight: bold;

  

  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: relative;
  bottom: 48px;
  left: 5px;
  cursor: text;
}

.green-input.fill {
  display: block;
  width: 100%;
}

.g-input input:focus,
.g-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);
  box-shadow: none;
}
.g-input label:focus{
  font-weight: 100;
}


.start-input input:focus,
.start-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}
.start-input label:focus{
  font-weight: 100;
}

.T-input input:focus,
.T-input select:focus,
.T-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}


.gpno-input input:focus,
.gpno-input select:focus,
.gpno-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}


.pno-input input:focus,
.pno-input select:focus,
.pno-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-green-alt);

}


.green-input input:focus,
.green-input select:focus,
.green-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-green-alt);

}



.required-field::after {
  content: "*";
  position: absolute;
  color: var(--godrej-red-alt);
}

.g-input input:focus+label,
.g-input input:not(:placeholder-shown)+label,
.g-input textarea:focus+label,
.g-input textarea:not(:placeholder-shown)+label {
  transform: translateX(1%) translateY(-95%);
  padding: 3px;
  color: var(--col-707070);
  background-color: white !important;
  font-size: 12px;
  font-weight: 100;
}


.start-input input:focus+label,
.start-input input:not(:placeholder-shown)+label,
.start-input textarea:focus+label,
.start-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  font-weight: 100;
}



.g-select {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.g-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  cursor: text;
  
}

.g-select select {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-family: "GE-BodyCopy",sans-serif;
  font-size: 14px;
  height: 40px;
}

.g-select.fill {
  display: block;
  width: 100%;
}

.g-select select:focus {
  outline: 0;
  border-color: var(--godrej-purple);
  box-shadow: none;
  font-family: "GE-BodyCopy",sans-serif;
  font-size: 14px;
}

.T-input input:focus+label,
.T-input select:focus+label,
.T-input input:not(:placeholder-shown)+label,
.T-input select:not(:placeholder-shown)+label,
.T-input textarea:focus+label,
.T-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-1%) translateY(-100%);
  font-size: 12px;
  font-weight: 100;
  color: var(--col-707070);
  background-color: white;

}

.T-input input:focus+label span,
.T-input input:not(:placeholder-shown)+label span {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  font-weight: 100;
}

.gpno-input input:focus+label,
.gpno-input select:focus+label,
.gpno-input input:not(:placeholder-shown)+label,
.gpno-input select:not(:placeholder-shown)+label,
.gpno-input textarea:focus+label,
.gpno-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  color: var(--godrej-green-alt);
  font-weight: 100;

}

.green-input input:focus+label,
.green-input select:focus+label,
.green-input input:not(:placeholder-shown)+label,
.green-input select:not(:placeholder-shown)+label,
.green-input textarea:focus+label,
.green-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  color: var(--godrej-green-alt);

}



.toast-message {
  position: absolute;
  top: 3%;
  left: 10%;
  width: 395%;
  height: 1%;
  opacity: 1;
}


.toast-position {
  height: 10px;
}

.allexport {
  cursor: pointer;
}

.RowHeight {
  margin-top: 40px;
}

.RowHeightWCS {
  margin-top: 150px;
}

.RowHeightTREQ {
  margin-top: 25px;
}

.inputsearchbox {
  color: var(--godrej-red);
}

.ListTitle {
  font-family: "GE-Headline";
  color: var(--txt-color);
  align-self: center;
  font-size: 18px;
  width: auto;
}


.req-input {
  letter-spacing: 0px;
  color: var(--godrej-red-alt);
  margin-left: 5px;
}

.form-check-input:checked {
  background-color: var(--godrej-green);
  border-color: var(--godrej-green);
}

.form-check-input:checked>label {
  color: var(--godrej-green);
}

.form-check-input:not(:checked)>label {
  color: var(--inactive-gray);
}



.green>.form-check-label {
  font-weight: bold;
  color: var(--godrej-green);
}

.gray>.form-check-label {
  font-weight: bold;
  color: var(--inactive-gray);
  padding-right: 3rem !important;
}

.form-switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.modal-style {
  color: var(--godrej-purple);
  font-weight: 600;
  font-size: 18px;
  border: none;
}
.my-modal {
  position: relative;
  top: 100px;
}



.rightSideLoginPage {

  flex-grow: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 3rem;
  text-align: center;
  padding: 0 3rem;

  z-index: 10;
  background-color: white;
  
}



@media (max-width: 600px) {

  .rightSideLoginPage {
    width: 100%;

    box-shadow: 0px 0px 0px 0px rgb(0, 0, 0,0.10);
  }
}  


.ag-paging-panel {
  display: flex !important;
  justify-content: space-between !important;
}


.nav-item .nav-link {
  color: var(--col-707070) !important;

  font: normal normal normal 14px/16px GE-BodyCopy,sans-serif;
  border-width: initial;
}


.nav-link.active {
  color: var(--godrej-purple) !important;
  font-weight: 600;
  border-bottom-color: var(--godrej-purple) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

.nav-link:hover {

  color: var(--godrej-purple) !important;
  font-weight: 600;
  border-bottom-color: var(--godrej-purple) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}


.active.nav-link {
  color: var(--godrej-purple) !important;
  border-bottom-color: var(--godrej-purple) !important;
}

.nav-tabs {
  border-width: none !important;
}

.border-end {
  border: none !important;
}

.thead-color {
  background-color: var(--bg-alt-2) !important;
}

.font {
  font: normal normal normal 14px/16px GE-BodyCopy,sans-serif;
}


.headwidth {
  width: 150px;
}

table.same-col-widths th {
  height: 60px;
}

table.tube-col-widths th {
  width: 28%;
  height: 60px;
}

.dInput {
  display: flex;
  justify-content: center;

  align-items: center;

}


.greenfont {
  color: var(--godrej-green);
}

.imgplace {
  position: relative;
  bottom: 31px;
  left: 65px;
}

.WpsInput input {
  width: 100%;
  height: 32px;
  border: 1px solid var(--godrej-purple);
  border-radius: 4px;
  padding-left: 6px !important; 
}

.required-text {
  position: relative;
  bottom: 6px;
  font-size: 12px;

}

.required-text1 {
  position: relative;
  bottom: 15px;
  font-size: 12px;

}

.required-text-select {
  position: relative;
  bottom: 26px;
  font-size: 12px;
}

.required-text-selectWeldRepair {
  font-size: 12px;
}

.row-margin {
  margin-top: -10px;
}

.radio-img-error {
  position: relative;
  left: 33px;
}

.radioheading {
  position: relative;
  left: 12px;
  top: 1px;
}



.checkbox-input {
  margin-right: 5px;
  margin-left: 5px;
  width: 15px;
  height: 15px;

}

.checkbox_label {
  position: relative;
  bottom: 4px;
  right: 5px;
}



.OR_label {
  position: relative;
  top: 5px;
  left: 15px;
}

.WpsInput-md input {
  width: 85px;
  height: 35px;
  border: 1px solid var(--col-ddd);
  border-radius: 4px;
  padding-left: 6px !important;
}
.WpsVariableInput-md input {
  width: 347px;
  height: 35px;
  border: 1px solid var(--col-ddd);
  border-radius: 4px;
  padding-left: 6px !important;
}

.WpqInput-md input {
  width: 120px;
  height: 35px;
  border: 1px solid var(--col-ddd);
  border-radius: 4px;
}

.WpsInput-md select {
  border: 1px solid var(--col-ddd);
}



.WpsInput-md input:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
}


.WpsInput-md input:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
}



.PQRInput-md input:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-color: var(--godrej-purple) !important;
}

.WpsInput-md select:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
}


.WpsInput-md input:focus-visible {
outline: none !important;
border-color: var(--godrej-purple);
}


.WpqInput-md input:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
}

.WpqInput-md select:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
}


.WpqInput-md input:focus-visible {
outline: none !important;
border-color: var(--godrej-purple);
}


.WpsInput-md select:focus-visible{
  outline: none !important;
border-color: var(--godrej-purple);
}



.WpsInput-sm input {
  width: 64px;
  height: 32px;
  border: 1px solid var(--col-ddd);
  border-radius: 4px;
  margin: 10px 10px 10px 10px;
}

.table>tbody>tr:hover>td {
  background-color: var(--bg-alt-2);
}

.selcectsize {
  width: "85px";
  height: "32px";
  border-color: var(--godrej-purple);
  border-radius: "4px";
  margin-right: "10px"
}


.error,
.error:hover>td {
  background-color: var(--godrej-red-bg) !important;
}



.error-input {
  border-color: var(--godrej-red) !important;
  background-color: var(--godrej-red-bg) !important;
}

.err-input {
  border-color: var(--godrej-red) !important;
}

.error-head {
  color: var(--godrej-red);
}

.table>tbody>tr>th>div>span {
  font-weight: 100;
  float: right;
}


.thickness_title {
  color: #FFFFFF !important;
  background: var(--col-707070) !important;
}

.Groove_green {
  color: var(--godrej-green);
}

.table>tbody>tr>th>div {
  display: flex;
  justify-content: space-between;
}

.table>tbody>tr>th {
  vertical-align: middle;
}

.table>thead>tr>th {
  vertical-align: middle;
}


.WPS-upload {
  background: var(--table-bg);
  border: 1px dashed var(--godrej-purple);
  display: flex;
}

.WPS-uploadwhite {
  background: var(--table-bg);
  border: 1px dashed var(--godrej-purple);
  display: flex;
}

.Image_text {
  font-size: 14px;
  position: relative;
  left: 30px;
}


.WPS-blue-text {
  color: var(--godrej-purple);
  margin-bottom: 25px;
  font-weight: 600;

}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: var(--txt-color);
  opacity: 1;
  display: block;
  background: url(../src/icons/DateIcon.svg) no-repeat;
  border-width: thin;
}

.fontWeight {
  font-weight: bold;


}

.fontWeightRadio {
  font-weight: bold;
  font-size: 12;


}

.WPSSelect .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 85px !important;
  min-height: 32px !important;
  height: 38px !important;
}
.WPSSelectProcess .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 140px !important;
  min-height: 32px !important;
  height: 38px !important;
}

.PQRSelect .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 175px !important;
  min-height: 32px !important;
  height: 38px !important;
}

.WPQSelect .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 175px !important;
  min-height: 32px !important;
  height: 38px !important;
}

.PQRSelectProcess .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 272px !important;
  min-height: 32px !important;
  height: 38px !important;
  position: relative !important;
  left: 4% !important;
}


.WPSSelectVariable .css-13cymwt-control {
  border-color: var(--godrej-purple) !important;
  width: 400px !important;
  min-height: 32px !important;
  height: 38px !important;
}



.WPSSelect .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 85px !important;
  min-height: 32px !important;
  height: 38px !important;
}
.WPSSelectProcess .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 140px !important;
  min-height: 32px !important;
  height: 38px !important;
}


.PQRSelect .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 175px !important;
  min-height: 32px !important;
  height: 38px !important;
}

.WPQSelect .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 175px !important;
  min-height: 32px !important;
  height: 38px !important;
}

.PQRSelectProcess .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 272px !important;
  min-height: 32px !important;
  height: 38px !important;
  position: relative !important;
  left: 4% !important;
}

.WPSSelectVariable .css-t3ipsp-control {
  border-color: var(--godrej-purple) !important;
  width: 400px !important;
  min-height: 32px !important;
  height: 38px !important;
}


.WPSSelect .css-t3ipsp-control:hover {
  border-color: var(--godrej-purple) !important;
  background-color: var(--bg-alt-2);
}

.WPSSelect:hover input{ border-color: var(--godrej-purple);}

.WPSSelect:hover select{ border-color: var(--godrej-purple);}

.WPSSelectVariable .css-t3ipsp-control:hover {
  border-color: var(--godrej-purple) !important;
  background-color: var(--bg-alt-2);
  width: 400px !important;
}

.WPSSelectVariable:hover input{ border-color: var(--godrej-purple);width: 400px !important;}

.WPSSelectVariable:hover select{ border-color: var(--godrej-purple); 
  width: 400px !important;}


.statusbg {
  background-color: var(--bg-alt-2);
  height: 30px;
}

.errorstatusbg {
  background-color: var(--bg-alt);
  height: 30px;
}

.statusborder {
  padding-top: 5px;
  font-weight: 500;
}

.errorstatusborder {
  padding-top: 5px;
  font-weight: 500;
  position: relative;
  left: 50%;
}


.WPSSelectsm {
  width: 85px !important;
  height: 1px !important;
}



.WPQvaruableSelectsm {
  width: 100px !important;
  height: 1px !important;
}



.tablewidth {
  table-layout: fixed;
}

.tablethwidth {
  width: 13.9%;
}

.tablethwidthp {
  width: 1.9%;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}


.T-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 18%;
  left: 3%;
  cursor: text;
}

.gpno-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 18%;
  left: 3%;
  cursor: text;
}


.select__control.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 0 !important;
  border: 1px solid #ced4da !important;
  color: var(--txt-color) !important;
  border-radius: 0.2rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  width: 270px; //only required for dropdown in modals
}



.css-t3ipsp-control {
  box-shadow: none !important;
  border-color: var(--godrej-purple) !important;
}

.css-tj5bde-Svg {
  fill: black !important;
  stroke: #ffffff !important;
  stroke-width: 1 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1nmdiq5-menu {
  font-weight: 200 !important;
}

.WPS-circle {
  position: relative;
  top: 8px;
  left: 8px;
  width: 50px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  border-radius: 50px;
}

.imgtext1 {
  position: relative;
  top: 5px;
  left: 1px;
  height: 16px;
  font: normal normal normal 10px/12px GE-BodyCopy,sans-serif;
  letter-spacing: 0px;
  color: var(--col-707070);
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.ImagenameBox {
  position: relative;
  width: 50%;
  height: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: 80px;
}



.WPS-box {
  position: relative;
  top: 10px;
  left: 8px;
  width: 10px;
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
}

input[type=text]:focus {
  border-color: var(--godrej-purple);
}



.gpno-input input[type=text]:focus {
  border-color: var(--godrej-green-alt) !important;
  border: 1px solid;
}

.green-input input[type=text]:focus-visible {
  border-color: var(--godrej-green-alt) !important;
  border: 1px solid;

}



.search-select input[type=text]:focus-visible {
  border-color: var(--godrej-green-alt) !important;
  border: 1px solid;

}

//WPS Transaction*--End

//WCS  start
.allow-wcs {
  width: 100%
}

.hr-text {
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  opacity: 1;

  &:before {
    content: '';
    // use a solid background color for a solid bar
    background: gray;
    position: absolute;
    color: gray;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    font-weight: bold;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: white;
  }
}

//WCS - till here

//Tooltip-css- Start
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  padding: 6px;
  color: var(--txt-color);
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E5E5E5;
  white-space: pre-wrap;
  left: 50%;
  top: 35px;
  transform: translateX(0) translateY(-50%);
  font-weight: 100;
  z-index: 10;
  width: 300px;
}

.Tooltip-TipWPQ {
  position: absolute;
  border-radius: 4px;
  padding: 6px;
  color: var(--txt-color);
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E5E5E5;
  white-space: pre-wrap;
  left: 50%;
  top: 35px;
  transform: translateX(-50%) translateY(0);
  font-weight: 100;
  z-index: 10;
  width: 400px;
}

.Tooltip-Tip-Detail {
  position: absolute;
  border-radius: 4px;
  padding: 6px;
  color: var(--txt-color);
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E5E5E5;
  white-space: nowrap;
  right: 20%;
  top: px;
  transform: translateX(0) translateY(-250%);
  font-weight: 100;
  z-index: 10;
  color: var(--godrej-red-alt);
}

.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

//Tooltip-css- End


.css-13cymwt-control {
  height: 40px;
  width: 77%;
  font-weight: 500;
  font-size: 89%;
}

.repairSelectDD .css-13cymwt-control {
  width: 100%;
}

.repairSelectDD .css-t3ipsp-control {
  width: 100%;
}

.repairSelectDD .css-16xfy0z-control {
  width: 100%;
}

.css-16xfy0z-control {
  width: 77%;
}

.search-select .css-16xfy0z-control{
  width: 100%;
}

.PQRSelectProcess .css-16xfy0z-control {
  width: 272px !important;
  position: relative !important;
  left: 4% !important;
}


.css-t3ipsp-control {
  width: 77%;
}

.search-select .css-t3ipsp-control{
  width: 100%;

}
div[class*="css-"].select__control:not(.WPSSelectsm div[class*="css-"].select__control){
  width: 100%;  
}



.checkboxproject {
  border-color: var(--godrej-green);
}

.checkboxcolor {
  accent-color: var(--godrej-green);
}

.input_checkbox {
  display: flex;
}


.MuiInputBase-input {
  box-sizing: content-box !important;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  transform-origin: top left !important;
  top: 0px !important;
  left: 0px !important;
}
.MuiFormLabel-root {
  transform-origin: top left !important;
  top: -5px !important;
  left: 0px !important;
}

.MuiInputBase-input {
  border: 0px !important;
}

.text-overflow {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}


.vertical-line {
  width: 0px;
  border-left: 1px solid #000;
  height: 100%;
  float: left;
}

.v-line {
  position: absolute;
  right: 0;
  top: 36%;
  background: white;
  left: 0;
  margin: 0 auto;
  text-align: center;
  width: 20px;
  height: 20px;
}

.resetbtn {
  position: relative;
  top: 10px;
  // right: 100px;
  left: 2px;
  width: 109px;
  height: 40px;
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
}

.submitbtn {
  top: 525px;
  left: 218px;
  width: 109px;
  height: 40px;
  background: var(--godrej-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
}

.semiBoldToggleButton {
  font-family: "GE-BodyCopy",sans-serif !important;
  font-size: 14px;
}

.btn-add-color {
  background-color: var(--godrej-purple);
  width: 109px;
  height: 40px;
  //margin-right: 1rem;
}


.edit-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-97%);
  font-size: 12px;
  color: var(--col-707070);
  background-color: white;
}

.edit-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  // background: white;
  // font-weight: bold;

  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: relative;
  bottom: 43px;
  left: 5px;
  /* left: 15px; */
  cursor: text;
}




.proj-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-100%);
  color: var(--col-707070);
  font-size: 12px;
  font-weight: 100;
}

.proj-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  // font-weight: bold;

  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: relative;
  bottom: 43px;
  left: 7px;
  /* left: 15px; */
  cursor: text;
}

.preby-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-150%);
  font-size: 12px;
  color: var(--col-707070);
}

.preby-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  // font-weight: bold;

  // padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  position: relative;
  bottom: 39px;
  left: 5px;
  /* left: 15px; */
  cursor: text;
}

.clrbtn {
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: 600;
  margin-right: 1.25rem;
  width: 86px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
}



.nobtn {
  top: 525px;
  left: 218px;
  width: 109px;
  height: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
}

.deletebtn {
  top: 525px;
  left: 218px;
  width: 109px;
  height: 40px;
  background: var(--godrej-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
}

#address3 {
  font-size: 8px !important;
}

#address1 {
  font-size: 7px !important;
}

#address2 {
  font-size: 10px !important;
  position: relative;
  top: 0.5 cm;
}

#tablemacro {
  border-bottom: .1vw solid black !important;
}

#Act {
  justify-content: flex-start !important;
}

#Rang {
  justify-content: flex-end !important;
}

#tt1 {
  position: relative;
  right: 6%;
}

#tt2 {
  position: relative;
  left: 6%;
}

.pagination {
  font-size: 8px;
  text-align: right;
  font-weight: bold;

}


//For Drop box WCS - from here

/* //for drop=zone */
.dropZoneContainer {
  position: relative;
  align-items: center;
  background: var(--table-bg);
  border: 1px dashed var(--godrej-purple);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  height: 75px;
}

.dropZoneMainDiv {
  max-width: 40%;
}

.container-dropzone {
  display: flex;
  justify-content: center;
  text-align: center;
}

.dropzone-fileupload {
  padding-bottom: 15px;
  text-align: center;
  align-items: center;
  width: 75%
}

.dropTextContainer {
  width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  text-align: center;
  align-content: center;
  align-items: center;
}

.dropSelectText {
  color: var(--godrej-purple);
}

.fileupload-name {
  font-size: 14px;
  color: var(--godrej-purple);
  font-weight: bold;
  text-align: center;
}

.fileuploadTitle {
  font-size: 14px;
  font-family: "GE-BodyCopy",sans-serif;
  font-weight: 500;
  text-align: left;
}


.wcs-dropzone {
  text-align: center;
  font-weight: bold;
  display: flex;
  width: 100%;
  justify-content: center;
}

//--till here

.add-new-link {
  text-decoration: underline;
  color: var(--godrej-purple);
  font-family: "GE-Headline", sans-serif;
}

.show-more-link {
  text-decoration: underline;
  color: var(--godrej-purple);
  font-size: 12px;
  font-weight: 600;
  font-family: "GE-Headline", sans-serif;
}

#Rstatus {
  width: 19cm !important;
}

.cardContainer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid var(--col-ddd);
}

.form-select:disabled {
  background-color: white;
}

.colHeader {
  flex: 1 0;
  width: 40%;
  color: var(--col-707070);
  text-align: left;
  font-size: 12px;
  font-family: "GE-BodyCopy",sans-serif;
  margin-bottom: 0.5rem;
  padding-right: 0px;
}

.colData {
  flex: 1 0;
  width: 60%;
  padding-left: 0px;
  color: var(--txt-color);
  text-align: left;
  font-size: 12px;
  font-family: "GE-BodyCopy",sans-serif;
  margin-bottom: 0.5rem;
}

.table-containerDeviation {
  overflow: auto;
}

.customerArrowResponsive {
  margin-right: 26px;
  display: inline-flex;
  justify-content: right;
}

.deviation-cards-header {
  height: 30px
}

.prodheight {
  height: 36px;
}

.sendapproval {
  z-index: 6;
  position: fixed;
  // bottom: 135px;
  bottom: 15px;
  left: 85%;
  background: var(--godrej-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  height: 28px;
  vertical-align: top;
  padding-top: 0;
}

.wcsreject {
  z-index: 6;
  position: fixed;
  //bottom: 135px;
  bottom: 10px;
  left: 89%;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
  height: 28px;
  vertical-align: top;
  padding-top: 0;
}

.wcsapprove {
  background:var(--godrej-purple);
  border: 1px solid var(--godrej-purple);
  color:"white";
  width:"109px";
  height:"40px";
  padding-top:"5px";

  z-index: 6;
  position: fixed;
  //bottom: 135px;
  bottom: 10px;
  left: 79%;

  // background: #FFFFFF 0% 0% no-repeat padding-box;
  // border: 1px solid #3339CD;
  // opacity: 1;
  // color: #3339CD;
  // height: 28px;
  // vertical-align: top;
  // padding-top: 0;

  
}

.wcscancel {
  z-index: 6;
  position: fixed;
  //bottom: 135px;
  bottom: 10px;
  left: 18%;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
  height: 28px;
  vertical-align: top;
  padding-top: 0;
}


.deviationShowMore {
  display: block;
}

.deviationShowLess {
  display: none;
}

.responsive-card-footer {
  background-color: white;
}

.card-footer {
  border-top: none;
}

.btn-outline-success {
  border-radius: 100px;
  // width: 100px;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-color: var(--godrej-purple);
  --bs-btn-hover-border-color: var(--godrej-purple);
}




.cbp_tmtimeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative
}

.collapsible-header {
  background-color: #dfe5f8 !important;
  padding-left: 0 !important;
}

.collapse-header {
  height: 2rem;
}

.top-18 {
  top: 16% !important;
}

// .drop-animate{
//   animation: growDown 300ms ease-in-out
// }
// forwards{
//   transform-origin: top center;
// }

// @keyframes growDown {
//   0% {
//     transform: scaleY(0);
//   }
//   30% {
//     transform: scaleY(0.5);
//   }
//   80% {
//     transform: scaleY(1.1);
//   }
//   100% {
//     transform: scaleY(1);
//   }
// }

.history-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 90% !important;
}

.accordion-button::after {
  margin-left: 2rem !important;
  color: var(--godrej-purple) !important;
  display: none;
}


// .cbp_tmtimeline:before {
//     content: '';
//     position: absolute;
//     top: 30px;
//     bottom: 0;
//     width: 1px;
//     background: $aemBlue;
//     left: 4%;
//     margin-left: -6px;
//     // height: calc(100% - 5.5rem)
// }

.cbp_verticalline:before {
  content: '';
  position: absolute;
  top: 30px;
  bottom: 0;
  width: 1px;
  background: $aemBlue;
  left: 4%;
  // margin-left: -6px;
  margin-left: -19px;
  height: 8rem;
}


.cbp_dottedline:before {
  content: '';
  position: absolute;
  top: 30px;
  bottom: 0;
  width: 1px;
  // background: $aemBlue;
  left: 3%;
  margin-left: -6px;
  height: 8rem;
  border-left: 1px dashed;
  color: $aemBlue;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.height6:before {
  height: 6rem !important;
}

.left43 {
  left: 7.6% !important;
}

.timHed {
  // font-size: 20px;
  // font-weight: 800;
  font: normal normal 600 16px/24px GE-BodyCopy,sans-serif;
  ;
}

.timHedSma {
  // font-size: 14px;
  color: var(--col-707070);
  font: normal normal normal 11px/11px GE-BodyCopy,sans-serif;
}

.timHedRem {
  // font-size: 12px;
  color: var(--col-707070);
  width: max-content;
  font: normal normal normal 12px/11px GE-BodyCopy,sans-serif;
}

.italic {
  font: italic normal normal 12px/16px GE-BodyCopy,sans-serif;
}


.certifont {
  font-family: 'Pacifico' !important;
}



.joinTable {
  width: 100%;
}

#data {
  position: relative;
  left: 17%;
}

// #weldpdata {
//   text-align: center !important;
//   position: relative;
//   left: 24%;
// }

// #weldpdata2 {
//   text-align: center !important;
//   justify-content: inherit !important;
//   position: relative;
//   left: 30%;
// }

// #weldpdata {
//   position: relative !important;
//   left: 3cm !important;
// }

#weldpdata4 {
  position: relative !important;
  left: 1cm !important;
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmtime span:last-child {
  color: var(--txt-color);
  font-size: 13px
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel {
  background: $aemWhite
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_label {
  background: $aemWhite
}

.cbp_tmtimeline>li:nth-child(odd) {
  border-right-color: $aemWhite
}

.cbp_tmtimeline>li .empty span {
  color: #777
}

.cbp_tmtimeline>li .cbp_tmtime {
  display: block;
  width: 23%;
  padding-right: 70px;
  position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
  display: block;
  text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
  font-size: 15px;
  color: #3d4c5a;
  font-weight: 700
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
  font-size: 14px;
  color: var(--txt-color)
}

.cbp_tmtimeline>li .cbp_tmlabel {
  // margin: 0 16px 15px 78px;
  margin: 0 1.3% 1.2% 6.5%;
  background: $aemWhite;
  padding: 1.2em;
  position: relative;
  border-radius: 5px;
  // width: 100%;
}

.cbp_tmtimeline>li .cbp_label {
  // margin: 0 16px 15px 78px;
  margin: 0 1.3% 1.2% 6.5%;
  background: $aemWhite;
  padding: 1.2em;
  position: relative;
  border-radius: 5px;
  // width: 100%;
}

// .cbp_tmtimeline>li .cbp_tmlabel:after {
//     right: 100%;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//     border-right-color: $aemWhite;
//     border-width: 10px;
//     top: 10px
// }

.cbp_tmtimeline>li .cbp_tmlabel blockquote {
  font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel .map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px var(--col-ddd);
  -webkit-box-shadow: 0px 0px 0px 1px var(--col-ddd);
  box-shadow: 0px 0px 0px 1px var(--col-ddd);
  background: #fff !important
}

.cbp_tmtimeline>li .cbp_tmlabel h2 {
  margin: 0px;
  padding: 0 0 10px 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a {
  font-size: 15px
}

.cbp_tmtimeline>li .cbp_label h2 a {
  font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a:hover {
  text-decoration: none
}

.cbp_tmtimeline>li .cbp_tmlabel h2 span {
  font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
  color: var(--txt-color)
}

.cbp_tmtimeline>li .cbp_label p {
  color: var(--txt-color)
}

.cbp_tmtimeline>li .cbp_tmicon {
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: white;
  border-radius: 50%;
  // box-shadow: 0 0 0 5px #f5f5f6;
  border: 1px solid $aemBlue;
  text-align: center;
  left: 4%;
  top: 30px;
  margin: 0 0 0 -25px
}


.status-check-radios {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  column-gap: 37px !important;
}

.tim-status {
  .form-check-label {
    padding-top: 2px !important;
  }
}

.timHedTrdI-width {
  width: max-content;
  margin-left: -4px;
}



.wcsT-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
}

.c-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2%;
  width: 600;
}


.resetindex {
  z-index: -2;
}

.WIloader {
  position: relative;
  top: 300px;
  left: 480px;
}



.wcsT-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  // font-weight: bold;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--col-707070);
  // font-family: arial;
  position: absolute;
  top: 5px;
  left: 7px;
  cursor: text;
}



.borderhidden {
  border-bottom-style: hidden;
}

.wcsT-input input,
.wcsT-input select,
.wcsT-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  height: 45px;
}

.wcsT-input.fill {
  display: block;
  width: 100%;
}



.groove_date {
  position: relative;
  bottom: 8px;
  left: 3px
}

._date {
  position: relative;
  bottom: 8px;
  left: 3px
}




.normal-input-select:has(.select__value-container--has-value, .css-166bipr-Input, .select__control--menu-is-open) label {
  transform: translateX(1%) translateY(-67%);
  font-size: 12px;
  // color: #1a73e8;
}




.wcsT-input input:focus,
.wcsT-input select:focus,
.wcsT-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}


.gpno-input input:focus,
.gpno-input select:focus,
.gpno-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}


.pno-input input:focus,
.pno-input select:focus,
.pno-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-green-alt);

}


.green-input input:focus,
.green-input select:focus,
.green-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-green-alt);

}



.g-select select:focus+label,
.g-select select:not(:placeholder-shown)+label,
.g-select textarea:focus+label,
.g-select textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  color: var(--col-707070);
  font-family: "GE-BodyCopy",sans-serif;
}


.wcsT-input input:focus+label,
.wcsT-input select:focus+label,
.wcsT-input input:not(:placeholder-shown)+label,
.wcsT-input select:not(:placeholder-shown)+label,
.wcsT-input textarea:focus+label,
.wcsT-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  // color: #1a73e8;

}

.gpno-input input:focus+label,
.gpno-input select:focus+label,
.gpno-input input:not(:placeholder-shown)+label,
.gpno-input select:not(:placeholder-shown)+label,
.gpno-input textarea:focus+label,
.gpno-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  color: var(--godrej-green-alt);
  font-weight: 100;

}

.green-input input:focus+label,
.green-input select:focus+label,
.green-input input:not(:placeholder-shown)+label,
.green-input select:not(:placeholder-shown)+label,
.green-input textarea:focus+label,
.green-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-1%) translateY(-85%);
  font-size: 12px;
  font-weight: 100;
  // color: #1a73e8;
  color: var(--godrej-green-alt);

}

.my-modal {
  position: relative;
  top: 100px;
}

.nav-tabs {
  border-width: none !important;
}




// .fillermetalheader {
//   width: 190px;
// }

.fillermetalheader {
  inline-size: 260px;
  overflow-wrap: break-word;
}




// .WpsInput-md input:focus {
//   background: #FFFFFF 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 6px #00000029;
//   border: 1px solid var(--godrej-purple) !important;
// }

.WpsInput-sm input {
  width: 64px;
  height: 45px;
  border: 1px solid var(--godrej-purple);
  border-radius: 4px;
  margin: 10px 10px 10px 10px;
}

// .table>tbody>tr:hover>td {
//   background-color: var(--bg-alt-2);
// }

// .table>tbody>tr:hover>td input {
//   background-color: var(--bg-alt-2);
// }

// .table>tbody>tr:hover>td select {
//   background-color: var(--bg-alt-2);
// }

table .border:hover>td {
  background-color: var(--table-bg) !important;
}


.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}


.wcsT-input-select label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  /* font-weight: bold; */
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  position: absolute;
  top: 18%;
  /* left: 15px; */
  left: 3%;
  /* z-index: 1; */
  cursor: text;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}


.divindex {
  z-index: 0;
}



.btn-add-color {
  background-color: var(--godrej-purple);
  width: 109px;
  height: 38px;
  //margin-right: 1rem;
}




.show11 {
  position: relative;
  height: 40px;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
}

//WCS start here
.tempSave-col {
  display: flex;
  justify-content: end
}

.tempSave {
  width: 112px;
}

.excelupload {
  width: 180px;
  display: flex;
  justify-content: center;
}

.uploadexcelbtn {
  width: 150px;
  height: 29px;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--godrej-purple);
  opacity: 1;
  color: var(--godrej-purple);
  font-size: 12px;
  padding: 0;
  // position: relative;
  // right: 110px;
}

.viewNoteLbl {
  width: 90px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: var(--godrej-purple);
  font-size: 12px;
  padding: 0;
  vertical-align: top;
  // position: relative;
  // right: 140px;
}

.submitexcelbtn {
  display: flex;
  justify-content: end;
}

// till here 


.showresult {
  height: 29px;
  width: 100px;
  background: var(--godrej-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  font-weight: 500;
  vertical-align: top;
  text-align: top;
  padding-top: 0;
  font-size: 12px;
  // position: relative;
  // right: 19px;
}

.addwcs-input {
  position: relative;
  //margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 45px;
}

.addwcs-input label {
  font-family: "GE-BodyCopy",sans-serif;
  box-sizing: border-box;
  background: white;
  padding: 3px;
  // font-weight: bold;
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  color: var(--txt-color);
  // font-family: arial;
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: text;
}

.addwcs-input input,
.addwcs-input select,
.addwcs-input textarea {
  border: 1px solid var(--col-ddd);
  display: block;
  padding: 6px;
  border-radius: 3px;
  box-sizing: border-box;
}

.addwcs-input.fill {
  display: block;
  width: 100%;
}

.addwcs-input input:focus,
.addwcs-input textarea:focus {
  outline: 0;
  border-color: var(--godrej-purple);

}

.addwcs-input input:focus+label,
.addwcs-input input:not(:placeholder-shown)+label,
.addwcs-input textarea:focus+label,
.addwcs-input textarea:not(:placeholder-shown)+label {
  transform: translateX(-4px) translateY(-15px);
  font-size: 12px;
  color: var(--col-707070);
}


.detailbtnn {
  position: relative;
  text-align: left;
  display: inline-block;
  order: 2;
  width: 60%;
  top: 8px;
}

.devreportDate {
  flex-direction: column;
}

/* Welding repair analysis CSS start */
.weldRepairFirstDiv {
  background-color: #FAFAFA;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.weldRepairSecondDiv {
  margin-left: 5rem;
  margin-top: 1rem;
  margin-right: 5rem;
}

.pending-recs {
  margin-top: 0.75rem;
}

.pending-recs .heading {
  color: var(--txt-color);
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}

.dashboardTable {
  width: 40%;
  flex-flow: column;
}

.statusHighlightGreen {
  border-radius: 5px 5px 0px 0px;
  height: 10px;
  background: $aemGreen;
}

.statusHighlightGrey {
  border-radius: 5px 5px 0px 0px;
  height: 6px;
  background: $aemGrey;
}

.chartHeader .chartHeaderDate .chartHeaderYear {
  width: 2.5rem;
  line-height: 12px;
}

.table-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.status-color {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 48px;
  border-radius: 5px 0px 0px 5px;
  margin-right: 0.5rem;
}

.accordion-item:first-of-type .accordion-button {
  width: -webkit-fill-available
}

.modal-90w .table-row {
  padding: 0;
}

.app-content {
  padding: 1rem;
  height: calc(100vh - 5.5rem);
}

.background {
  background-color: #dfe5f8;
}

.table-body2 {
  min-width: 100%;
  max-width: 100%;
  flex-flow: column;
  display: table-cell;
}

.tableCellCustom {
  padding-left: 0.4rem;
}

.rowWidth {
  width: 62vw;
}

.table-cell2 {
  min-width: 125px;
  width: 25%;
  max-width: 140px;
  font-size: 14px;
  margin-left: 3rem;
  text-align: left;
  align-items: center;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: break-spaces;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: block;
    //width: 100px;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.pageloader {
  position: relative;
  top: 0px;
  left: 25px;
  bottom: 50px;
  z-index: 5;
}

.grid-container {
  position: relative;
  top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  // border: 1px solid black
}

.weldrepairtitle {
  display: flex;
  position: relative;
  top: 30px;
}

.weldrepairview {
  display: flex;
  position: relative;
  top: 250px;
}

.weldrepairtitlebottom {
  display: flex;
  position: relative;
  top: 260px;
}

.weldrepairviewbottom {
  display: flex;
  position: relative;
  top: 495px;
}


.repairtitleleft {
  position: relative;
  left: 10px;
}


.repairviewleft {
  position: absolute;
  left: 43%;
  font-size: 14px;
}

.repairviewleftbottom {
  position: absolute;
  // top: 445px;
  left: 43%;
  font-size: 14px;
  z-index: 1000;
  text-decoration: underline;
}

.repairtitleright {
  position: absolute;
  left: 50.5%;
  // font-size: 14px;

}


.repairviewright {
  position: relative;
  // top: 215px;
  left: 92.5%;
  font-size: 14px;
}

.repairviewrightbottom {
  position: relative;
  // top: 445px;
  left: 92.5%;
  font-size: 14px;
  z-index: 1000;
  text-decoration: underline;
}


.repairgridtableleft {
  position: relative;
  left: 10px;
  width: 97%;
  border: 1px solid black;
  bottom: 81px;
  border-color: var(--col-ddd);
}

.repairgridtableleftbottom {
  position: relative;
  left: 10px;
  width: 97%;
  border: 1px solid black;
  bottom: 40px;
  border-color: var(--col-ddd);
}

.repairgridtableright {
  position: relative;
  right: 5px;
  width: 97%;
  border: 1px solid black;
  bottom: 81px;
  border-color: var(--col-ddd);

}


.repaitablegraph {
  position: relative;
  top: 15px;
  height: 170px !important;
  padding: 10px;
}

.repairgraphtoplabel{
  position: relative ;
  top: 10px ;
  left: 40%;
  font-size: 14px;
}

.repairxaxislabel {
  position: relative;
  top: 0px;
  left: 50%;
  font-size: 14px;
}

.repairgridtablerightbottom {
  position: relative;
  right: 5px;
  width: 97%;
  border: 1px solid black;
  bottom: 40px;
  border-color: var(--col-ddd);

}

.Repairviewallbackbutton{
  border: 0px;
  background-color: white;
}

// .weldgraphyeardropdown{
//   border: 0px;
//   background-color: #EFF3FC;
//   border-radius: 25px;
//   // width: 100px;
// }

.weldrepairtitledash{
  position: relative;
  top: 11px;
  right: 20px;
    height: 2px;
    background-color: var(--godrej-green-alt);
    width: 14px;
}

.Repairviewallbackbutton{
  border: 0px;
  background-color: white;
}

.weldgraphyeardropdown{
  border: 0px !important;
  background: #EFF3FC !important;
  border-radius: 25px !important;
  // width: 100px;
}

.weldrepairtitledash{
  position: relative;
  top: 11px;
  right: 20px;
    height: 2px;
    background-color: var(--godrej-green-alt);
    width: 14px;
}

/* Welding repair analysis CSS end */



// .sidebar::-webkit-scrollbar{ width: 0;}

// .sidebar{
//   scrollbar-width: none; 
//   -ms-overflow-style: none; 
// }

//Listofwelder local date
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  height: 37px !important;
  overflow-y: hidden !important;
}

.css-1ft40f5-MuiFormControl-root-MuiTextField-root{
  width: auto !important;
}
//date css for dev
.css-1bn53lx{
  height: 37px !important;
  overflow-y: hidden !important;
}

//Listofwelder local date
.css-4jnixx-MuiStack-root{
  flex-direction: column !important;
}
//date css for dev
.css-160unip{
  flex-direction: column !important;
}

.css-ekn7eb-MuiFormControl-root-MuiTextField-root{
  width: auto !important;
}

.css-1v50ers-control{
  border-color: var(--col-ddd) !important;
}


.wqrNote{
  font-size: 12px;
  font-family: "GE-BodyCopy",sans-serif;
  font-weight: 600;
  background-color: var(--table-bg);
  color: var(--godrej-purple);
  width: 1201px;
  height: 36px;
  padding-top: 8px;
  padding-left: 8px ;
  position: relative;
  // left: 10px;
  top: 10px;

}
//Listofwelder local date
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  width: auto !important;
}

//date css for dev
.css-1bn53lx{
  width: auto !important;
}

.css-1qddngy{
  width: auto !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  height: 17px !important;
}


// .ag-theme-material .ag-root-wrapper{
//   border: 1px solid black;
// }

.ag-root-wrapper.ag-layout-normal{
  height: 95% !important;
}


// .css-ekn7eb-MuiFormControl-root-MuiTextField-root{
//   width: 100px;
// }



.scrollpqr{
  margin: 4px, 4px;
            padding: 4px;
            width: auto;
            overflow-x: hidden;
            overflow-y: hidden;
            white-space: nowrap;
}

.scrollpqr:hover{
  overflow-x: auto;

}

.scrollwps:hover{
  overflow-x: auto;
  overflow-y: hidden;

}

.prodsave{
  z-index: 6;
  position: relative;
  top: 5px;
  // bottom: 135px;
  // bottom: 10px;
  // left:90%;
  background: var(--godrej-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  height: 28px;
  vertical-align: top;
  padding-top: 0;
  width: 100px;
  height: 38px;
  right: 90px;
  // padding-right: 90px;
  float: right;
  }

.css-z7a727-control{
  border-color: var(--col-ddd) !important;
}

.css-z7a727-control:hover {
  border-color: var(--godrej-purple) !important;
}

.css-z7a727-control:focus {
  border-color: var(--godrej-purple) !important;
}


.avatarcolor1{
  background-color: var(--godrej-blue-bg) !important;
  color: var(--godrej-blue) !important;
  font-weight: bold;
}

.avatarcolor2{
  background-color: var(--godrej-orange-bg) !important;
  color: var(--godrej-orange) !important;
  font-weight: bold;

}


.avatarcolor3{
  background-color: var(--godrej-green-bg) !important;
  color: var(--godrej-green) !important;
  font-weight:bold;

}

.btn-outline-primary:hover{
  background-color: var(--godrej-purple) !important;
  border-color: var(--godrej-purple) !important;
  color: white !important;
}

.btn-outline-primary{
  border-radius: 100px !important;
  border-color: var(--godrej-purple) !important;
  color: var(--godrej-purple)  !important;
}

.transnote{
  font-weight: bold;
  height: 50px;
}


.css-1v50ers-control{
  // height: 37px !important;
}

.bgcoo{
  background: 'none' !important;
}


.select::-ms-expand {
  display: none !important;
}

.select{
  appearance: none !important;
  background: url("./icons/DownArrow.svg") no-repeat !important;
}


// select:not(:-internal-select-author-button) {
//   appearance: none !important;
//   background: url("./icons/DownArrow.svg") no-repeat !important;
// background-position: right center !important;
// padding-left: 3px;
// // background-color: white !important ;

// }


.bordertab{
  border-bottom: 1px solid var(--col-ddd) !important;
}

.hover-border:hover,
input[class^=ag-]:focus{
  border: 1px solid var(--godrej-purple) !important;
  height: 40px !important;
  // width:  105px !important;
  margin-top: 4px !important;

}

.hover-border{
  // border: 1px solid black !important;
  height: 40px !important;
  // width:  105px !important;
  margin-top: 4px !important;
  border: 1px solid var(--bg-alt-2) !important;
}


.wpsgridcell{
  border-radius: 4px !important;
  line-height: 2.5px !important;
}


.ag-cell-inline-editing select{
  height: 30px !important;
  border: none !important;
}

.ag-cell-inline-editing select:focus{
  border: none !important;
  outline: none !important;
}


.ag-cell{
  // height: 100% !important;
  // height: 100px !important;
  // position: relative ;
  // top: 7px 
line-height: 2.5px
}

// .accordion{
//   --bs-accordion-active-bg :"var(--bg-alt-2)" !important
// }
// .--bs-accordion-active-bg{
//   background-color:"var(--bg-alt-2)" !important
// }

.accordion-button:not(.collapsed){
  background: var(--table-bg) !important
}

.accordion-item:last-of-type .accordion-button.collapsed{
  background: var(--table-bg) !important

}


.sucessapproved{
  background: "#E9FAE9" !important;
}

.rejctedapproved{
  background: var(--godrej-red) !important;
}

.inprogressapproved{
  background: var(--godrej-orange) !important;
}

.wcsaddtextbox{
  position: relative;
  right: 33px;
  top: 12px
}


.hide_Container{
  z-index: "-10" !important;
}


.Contain{
  overflow: auto !important;
  height: 80% !important;
}

.nowrap{
  position: relative;
  white-space: nowrap;
}

.wqcwelder{
  position: relative;
  left: 200px;
  width: 200%;
}
.wqcnowelder{
  width: 200%;
}

.wqcwpqno{
  position: relative;
  left: 80px;
}




.hover_btttn .default_img{
  height: 25px;
  position: relative;
  bottom: 4px;
  right: 6px;
  display: block;
}

.hover_btttn .hover_img{
  height: 25px;
  position: relative;
  bottom: 4px;
  right: 6px;
  display: none;
}

.hover_btttn:hover .default_img{
  height: 25px;
  position: relative;
  bottom: 4px;
  right: 6px;
  display: none;
}

.hover_btttn:hover .hover_img{
  height: 25px;
  position: relative;
  bottom: 4px;
  right: 6px;
  display: block;
}





// .no-hover-effect .nav-link .nav-link.active {
//   background-color: none !important; /* Remove hover background */
//   color: inherit !important; /* Keep text color unchanged */
//   border: none !important;
// }

// .no-hover-effect .nav-link:hover {
//   background-color: none !important; /* Remove hover background */
//   color: inherit !important; /* Keep text color unchanged */
//   border: none !important;

// }


// -------------------------------------  