.thead-bg-color {
    width: 100px;
    background-color: var(--bg-alt-2);
  }
  
  .PQR-Page-row-padding,
  .WPQ-Page-row-padding,
  .WPS-Page-row-padding {
     /* padding: 20px 60px; */
    /* background-color: #FAFAFA; */
  }
  
  .gridloader > span,
  .shift > span,
  .pageloader > span,
  .gridimgloader > span,
  .gridimgdownloadloader > span {
    background-color: var(--godrej-purple) !important; /* blue */
  }
  .btn-primary
  {
    --bs-btn-bg: var(--godrej-purple) !important;
    --bs-btn-hover-bg: var(--godrej-purple) !important;
    --bs-btn-border-color: transparent !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-disabled-bg: hsl(320, 100%, 30%) !important;
    --bs-btn-disabled-border-color: transparent !important;
    --bs-btn-active-bg: #4b0032 !important;
    --bs-btn-active-border-color: var(--godrej-purple) !important;
    border-radius: 100px !important;
  }
  .btn-primary:focus {
    box-shadow: 0 0 0 .25rem var(--table-bg);
  }
  
  /* All fields are mandatory */
  .warning-text {
    font-size: small;
    color: var(--godrej-red);
  }
  
  .info-text {
    font-size: small;
    color: var(--godrej-purple);
  }

  .form-check-input:focus {
    border-color: var(--table-bg);
    box-shadow: 0 0 0 .25rem var(--table-bg);
  }
  
  .WPSSelect select {
    width: 85px;
    height: 32px;   
    border-color: var(--godrej-purple);
    border-radius: 4px;
  }
  .status-color-inactive {
    color: var(--col-707070);
    background-color: #70707026;
  }
  .status-color-active {
    color: var(--godrej-green);
    background-color:#33C03726;
  }
  
  .action-status-color,
  .label-color  /* AgGridDefination */
  {
    color: var(--godrej-purple);
  }
  
  .itemGroup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border: 1.5px solid var(--godrej-purple);
    border-radius: 5px;
  }
  .info_bg{
    background-color: var(--table-bg);
  }

.search-select .select__control {
  height: 45px;
}
div[class*="css-"]{
  box-shadow: none;
  border-color: var(--col-ddd);
}
div[class*="css-"]:hover,
div[class*="css-"]:focus {
  /* input color */
  border-color: var(--godrej-purple);
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: var(--col-707070) !important;
  font-size: 12px;
}
.select__control.select__control--is-disabled,
input:disabled {
  /* disabled dropdown input */
  color: var(--col-707070);
  background-color: var(--bg-alt-2); /* #F1F1ED4D */
}
.text-danger {
  color: var(--godrej-red-alt) !important;
  font-size: 10.5px !important;
}

.WPQ-Green-text {
  color: var(--godrej-green);
}

.Toastify__toast--success{
  background: var(--godrej-green) !important;
}
.Toastify__toast--warning{
  background: var(--godrej-yellow) !important;
}
.Toastify__toast--error{
  background: var(--godrej-red) !important;
}

 /* Role/User master page switch input (Active/Inactive) */
.form-check-input:focus,
.btn-close:focus {
  border-color: var(--table-bg); /* #86b7fe;*/
  box-shadow: 0 0 0 .25rem var(--table-bg); /* #86b7fe; */
}

.checkboxproject :checked {
  background-color: var(--godrej-green);
  border-color: var(--godrej-green);
}

input::-webkit-input-placeholder {
  font-family: 'GE-BodyCopy',sans-serif !important;
}

input:-ms-input-placeholder {
  font-family: 'GE-BodyCopy',sans-serif !important;
}

input:-moz-placeholder {
  font-family: 'GE-BodyCopy',sans-serif !important;
}

input::-moz-placeholder {
  font-family: 'GE-BodyCopy',sans-serif !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--godrej-purple) !important;
}

.Mui-focused {
  color: var(--godrej-purple) !important;
}

.Mui-selected {
  background-color: var(--godrej-purple) !important;
}

.MuiInputBase-root {
  height: 45px !important;
}

input[type="radio"] {
  accent-color: var(--godrej-green-alt);
}