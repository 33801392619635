.line {
    display: flex;
    flex-direction: row;
    position: relative;
    /* UI Properties */
    opacity: 0.3;
    order: 1;
    color: var(--col-707070);
    font-size: 38px;
    height: 100%;
    padding-top: 0px;
    /* padding-bottom: 10px; */
}
.account {
    display: flex;
    order: 2;
    width: 70%;
    position: relative;

}
.profilecontainer{
    order: 2;
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    flex-grow: 1;
    margin-top: 8%;
}
.profilename {
    /* Layout Properties */
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 2%;
    height: 40%;
    /* UI Properties */
    text-align: left;
    font: 'GE-BodyCopy',sans-serif, Regular;
   /* font-size: 1.2vw;*/
    letter-spacing: 0px;
    color: var(--txt-color);
    opacity: 1;
    order: 1;
    padding-left: 2%;
    font-size: 14px;
    line-height: 16px;
}
.profilename > b {
    font-family: 'GE-BodyCopyHighlight',sans-serif;
}
.arrow{
    order: 3;
    display: inline-block;
    position: relative;
    padding: 0%;

}



.profilemail {
    display: inline-block;
    position: relative;
    /* UI Properties */
    text-align: left;
    font: 'GE-BodyCopy',sans-serif, Regular;
  /*  font-size: 1vw;*/
    letter-spacing: 0px;
    color: var(--txt-color);
    opacity: 1;
    order:2;
    top: 0%;
    flex-shrink: 0;
    padding-left: 2%;
    font-size: 12px;
    line-height: 14px;
}




.profile_text {
    text-align: right;
    /* padding-top: 1%;
    padding: 2%; */
    font: 'GE-BodyCopy',sans-serif, Regular;
    font-weight: bold;
    font-size: 14px;
    opacity: 1;
    order: 1;
    display: inline-block;
    position: relative;
    align-self: center;
}

@media screen and (max-width : 600px) {
    .account {
        position: absolute;
        display: flex;
        width: 90%;
        height: 90%;
    
    }   
    .arrow{
        padding-top: 4%;
        
    }
    .line {

       display: none;
       visibility: hidden;
      
   
    }
    .profilemail{
        font-size: 12px;
    }
    .profilename{
        font-size: 14px;
    }

}

