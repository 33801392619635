@font-face {
    font-family: "GE-BodyCopy" ;
    src: 
    url('./fonts-rebranding/GE-BodyCopy.woff2') format('woff2'),
    url("./fonts-rebranding/GE-BodyCopy.ttf") format("truetype");
    font-style: bold;
        font-size: 7.6px;       
}
@font-face {
    font-family: "GE-BodyCopyHighlight" ;
    src: 
    url('./fonts-rebranding/GE-BodyCopyHighlight.woff2') format('woff2'),
    url("./fonts-rebranding/GE-BodyCopyHighlight.ttf")format("truetype");
    font-style: bold;
        font-size: 7.6px;       
}
 @font-face {
    font-family: "GE-BodyCopyHighlightItalic" ;
    src: 
    url('./fonts-rebranding/GE-BodyCopyHighlightItalic.woff2') format('woff2'),
    url("./fonts-rebranding/GE-BodyCopyHighlightItalic.ttf")format("truetype");
    font-style:italic;
    font-weight: bold;
        font-size: 7.6px;       
} 
 @font-face {
    font-family: "GE-BodyCopyItalic" ;
    src: 
    url('./fonts-rebranding/GE-BodyCopyItalic.woff2') format('woff2'),
    url("./fonts-rebranding/GE-BodyCopyItalic.ttf")format("truetype");
    font-style: italic;
        font-size: 7.6px;       
} 