.main {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  order: 1;
}

.head {
  position: relative;
  /* top: 26px; */
  text-align: left;
  font-family: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 24;
  letter-spacing: 0px;
  opacity: 1;
  width: 97%;
  height: 100%;
  order: 2;
}

.content {
  order: 2;
}

.box {
  position: absolute;
  left: 2%;
  top: 100px;
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 24;
  letter-spacing: 0px;
  color: var(--txt-color);
  opacity: 1;
}
.title>b {
  font-family: "GE-BodyCopyHighlight",sans-serif;
}
.outertitle {
  position: relative;
  top: 1%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.outertitlewcs {
  position: relative;
  top: 1%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* display: flex; */
  flex-direction: row;
}


.title1 {
  text-align: center;
  vertical-align: middle;
  /* font: Work Sans, Regular; */
  font-family: "GE-BodyCopy", sans-serif;
  font-weight: 800;
  font-size: 22px; 
   /* font-size: 2vw;  */
  letter-spacing: 0px;
  padding-left: 1%;
  /* padding-top: 1%; */
  width: max-content;
  overflow-wrap: break-word;
  order: 1;
  display: table-cell;
  align-items: center;
  width: 24%;
  text-align: left;
  padding-left: 1%;
  align-self: center;
  position: relative;
  bottom:45px;
  left:1%
}

.user-action {
  cursor: pointer;
  margin-top: 0.5rem;
}

.top {
  position: relative;
  left: 10px;
  width: 100%;
  display: block;
  height: 60px;
}

.mobilelogo {
  display: none;
}

.add_btn {
  position: relative;
  text-align: left;
  top: 0px;
  display: inline-block;
  flex: auto;
}
.btnn {
  position: relative;
  left:22px;
  text-align: left;
  display: inline-block;
  order: 2;
  /* width: 60%; */
  top: 8px;
  overflow: hidden;
}

.text {
  width: max-content;
  text-align: center;
  font-family: "GE-BodyCopy", sans-serif;
  font-weight:500; 
  font-size: 14px;
  color: #ffffff;
  opacity: 1;
}

.button {
  left: 161px;
  width: fit-content;
  height: 40px;
  background-color: var(--godrej-purple);
  /* Green */
  border: none;
  display: inline-block;
}

.button1 {
  border-radius: 20px;
}

.location {
  position: absolute;
  /* top: 10%; */
  left: 0px;
  /* padding-left: 1%; */
  width: 100%;

  height: 24px;
  /* UI Properties */
  background: #FFF;
  border: 1px solid var(--bg-alt-2);
  opacity: 1;
  z-index: 100;
}

.ltext {
  font: 'GE-BodyCopy',sans-serif, Regular;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 2px;
  top: 0px;
  left: 0px;
  height: 14px;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  color: var(--col-707070);
}
.ltext > a {
  font-family: 'GE-BodyCopyHighlight';
}
.mobile-logo {
  display: none;
  visibility: hidden;
}
.account1 {
  visibility: visible;
  order: 3;
  position: absolute;
  z-index: 10;
  top:0;
  right: 0;
  display: flex;
  flex-direction: row;
  /* width: 35%; */
  margin-right: 1.5rem;
  background-color: white;
}
.hamburger {
  display: none;
}

/* Left bar before avatar in navbar */
.account1::before {
  position: absolute;
  display: block;
  content: "";
  height: 40px;
  width: 1px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: var(--bg-alt-2);
}

@media screen and (max-width: 768px) {
  .account1 {
    display: none;
    visibility: hidden;
  }
  .mobile-logo {
    display: block;
    visibility: visible;
    order: 2;
    align-content: center;
    text-align: center;
    width: 100%;
  }

  .mobilelogo {
    display: flex;
    position: relative;
    top: 1%;
    overflow: hidden;
    text-align: center;
    height: auto;
  }
  .hamburger {
    display: block;
    position: relative;
    top: 1%;
    overflow: hidden;
    text-align: center;
    height: auto;
    order: 1;
  }
  .outertitle {
    display: block;
  }
  
  .main {
    margin: 0;
    padding: 0;

    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    width: 100%;
  }
  .head {
    position: relative;
    /* top: 26px; */
    text-align: left;
    font: 'GE-BodyCopy',sans-serif, Regular;
    font-size: 24;
    letter-spacing: 0px;
    opacity: 1;
    /* height: 10%; */
    z-index: -3;
  }
  .box {
    position: relative;
    left: 2%;
    font: 'GE-BodyCopy',sans-serif, Regular;
    font-size: 24;
    letter-spacing: 0px;
    color: var(--txt-color);
    z-index: 4;
    opacity: 1;

    width: 100%;
  }
  .blurrOverlay {
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    display: none;
    order: 2;
    z-index: -1
  }
  
  .logo {
    display: inline-block;
    top: 1%;
    overflow: hidden;
    order: 2;
    padding-left: 25%;
  }


/*   .btnn {
    position: static;
    display: inline-block;        
  }   */


  .top {
    position: relative;
    left: 10px;
    width: 100%;
    display: block;
    height: 100px;
  }

  .btnn {
    position: relative;
    left:0px;
    text-align: left;
    display: inline-block;
    order: 2;
    /* width: 60%; */
    top: 2px;
    /* overflow: hidden; */
  }
}




/* .css-1v50ers-control{
  width: 248px !important;
} */
