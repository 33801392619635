@import "../../../../Assests/font/fontGE.css";

.WPQMainPDF {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-top: 0;
    background-color: white;
    letter-spacing: 0.01px;
    width: 793px;
    height: 830px;
}
.JointDetailSpan{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    width: 90px;
    padding: 3px;
    position: relative;
    word-break: break-all;

}

.WPQfooterRow{
    order: 3;
    display: block;
    
 }
 .WPQheaderRow{
    order: 1;

    padding-top: 1px;
    font: "GE-BodyCopy";
    font-size: 8.5px;
    display: block;
}
.WPQContent{
    order: 2;
  
    flex: 2;
    display: block
    ;
   
  
}
.WPQflexb{
    display: flex;
    flex-direction: column;
   
    align-items: stretch;
    
}

.WPQcontainer {

    display: flex;
    height: 100%;
}


.WPQgroup {
    display: flex;
    align-items: left;
}

.WPQflexb {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
}

.WPQheaderRow {
    order: 1;
    flex-grow: 0;
}

.WPQContent {
    order: 2;
    min-height: 70%;
    flex-grow: 1;

}

.WPQfooterRow {
    order: 3;
    align-items: flex-end;
    flex-grow: 0;
    width: 790px;
}

.WPQvl {
    border-left: 1px solid black;

}

.WPQnoborder {
    border-bottom: none;
    border-top: none;
    border-left: none;
    background-color: white;
}

.WPQfooter {

    /* border-collapse: collapse; */
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    font:"GE-BodyCopy";

    font-weight: bold;
    table-layout: fixed;
    width: 72%;
    
}

.WPQfooter td {

    /* border-collapse: collapse; */
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: right;
    font:"GE-BodyCopy";
   font-size: 11px;

}

.WPQTable1 {
    width: 72%;
    border-collapse: collapse;
    /* border: 1px solid black; */
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    text-align: left;
    
}
.WPQheaderTable1 {
    width: 570px;
    border-collapse: collapse;
    /* border: 1px solid black; */
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    text-align: left;
    
}
.WPQTable2 {
    width: 72%;
    border-collapse: collapse;
    /* border: 1px solid black; */
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    /* text-align: center; */
    
}
.WPQcertifont{
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 7.5px;
    width: 72%;
}
.WPQTable7Child{
    width: 100%;
    border: none;
    padding: 0%;
    opacity: 0.2    ;
    background-color: inherit
}
.cssOpacity{
    opacity: 0.4;
}


.pdfwpqTable {
 
 
    width: 570px;
    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    font-size: 8.5px;
}

.pdfwpqTable td {
    /* border-right: none; */
    
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.pdfwpqTable th {
    /* border-right: none; */
    
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
    
    font-size: 8.5px;
    width: 34%;
}

.pdfttsbasemetal {
 
 
    width: 570px;
    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy";
    font-style: normal;
    table-layout: fixed;
    font-size: 8.5px;
}

.pdfttsbasemetal td {
    /* border-right: none; */
    
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.pdfttsbasemetal th {
    /* border-right: none; */
    
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
    
    font-size: 8.5px;
    width: 34%;
}

.WPQTableHeading {
 
 
    width: 72%;
    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopyHighlight";
    font-style: bold;
    font-weight: 900;
    table-layout: fixed;
    font-size: 8.5px;
}

.WPQTableHeading td {
    font-family: "GE-BodyCopyHighlight";
    font-style: bold;
    font-weight: 900;
    font-size: 8.5px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.WPQTableHeading th {
 
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopyHighlight";
    font-style: bold;
    font-weight: 900;  
    font-size: 8.5px;
    width: 34%;
}
.pdfLeftBorder {
    border-left: 1px solid black;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
}
.pdfLeftRightTopBorder {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
}

.pdfTopBorder {
    border-top: 1px solid black;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
}
.pdfBottomBorder {
    border-bottom: 1px solid black;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
}
.pdfwpqFontBold {
    /* font-weight: bold; */
    background: var(--col-ddd);
    border-top: 1px solid black;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    text-align: center;
}
.aligncenter{
    align-items: center;
    align-content: center;
    text-align: center;
}

.alignleft{
    align-items: left;
    align-content: left;
    text-align: left; 
}
.pdfwpqFontBoldtitle {
    font-weight: 900;
    font-style: bold;
    text-align: center;
    font-family: "GE-BodyCopyHighlight";
    font: "GE-BodyCopyHighlight";
}
.pdfwpqFontBoldtitle1 {
    font-weight: 900;
    font-style: bold;
    font-family: "GE-BodyCopyHighlight";
    font: "GE-BodyCopyHighlight";
    text-align: left;
}
.pdfRightBorder{
    border-right: 1px solid black !important;
}

.pdfTopBottom{
    border-top: 1px solid black ;
    border-bottom: 1px solid black ;
}
.pdfnoleft{
    border-top: 1px solid black ;
    border-bottom: 1px solid black ;
    border-right: 1px solid black ;
}
.pdfleftBottom{
    border-bottom: 1px solid black ;
    border-left: 1px solid black ;
}

.pdfwpqBorderRightRemoved {
    border-right: none !important;
}

.pdfwpqNoBorderBottom {
    border-bottom: none !important;
    font-weight: 400 !important;
    font-family: "GE-BodyCopy";
}

.pdfwpqBorderRightBottomRemoved {
    border-right: none !important;
    border-bottom: none !important;
}

.pdfwpqBorderLeftRightBottom {
    border-right: 1px solid black  !important;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.pdfwpqBorderRightTopRemoved {
    border-right: none !important;
    border-Top: none !important;
}

.pdfwpqBorderLeftTopRemoved {
    border-left: none !important;
    border-top: none !important;
}

.pdfwpqBorderTopBottomRemoved {
    border-top: none !important;
    border-bottom: none !important;
}
.pdfwpqLeftRightBorder{
    border-right: 1px solid black  !important;
    border-left: 1px solid black;
    border-bottom: none;
    border-top: none;
}

.pdfwpqBorderRightTopBottomRemoved {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
}


.pdfwpqBorderLeftRighTopRemoved {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    text-align: center;
}

.pdfwpqBorderLeftTopBottomRemoved {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
}
.pdfwpqallborders {
   
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    font-style: normal;
    padding-left: 3px;
    /* border:  1px solid black; */
    width: 570px;
  
}
.pdfwpqallborders th{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    background: var(--col-ddd);
    font-style: normal;
    padding-left: 3px;
}
.pdfwpqallborders td{
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    font-style: normal;
    padding-left: 3px;
}
.pdffont{
  font-family: "GE-BodyCopy";
  font: "GE-BodyCopy"; 
}
.pdfBorderBottom{
    border-bottom: 1px solid black !important;
}
.pdfTop{
    border-top: 1px solid black;
}
.pdfRight{
    border-right: 1px solid black;
}
.pdfBootom{
    border-bottom: 1px solid black;
}
.pdfTopLeft{
    border-top: 1px solid black;
    border-left: 1px solid black;
}

.pdfBottomLeft{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}
.pdfTopBottomRight{
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}
.pdfwpqBorderLeftRightTopBottomRemoved {
    border-left: none ;
    border-right: none ;
    border-top: none ;
    border-bottom: none ;
}
.PdfNoTopBorder{
    border-top: none !important;
}
.PdfNoLeftBorder{
    border-left: none !important;
}
.PdfNoRightBorder{
    border-right: none !important;
}

.PdfNoBottomBorder{
    border-bottom: none !important;
}
.WPQTable1 td {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;

}
.WPQheaderTable1 td {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;

}
.WPQTable2 td {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;

}
.WPQforfont{
    font-family: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
}
.WPQheaderTable1 th {
    padding-left: 1px;
    /* text-align: left; */
    background: var(--col-ddd);

    font-family: "GE-BodyCopy";
    font-style: normal;
    border-collapse: collapse;
    font-weight: normal;
    border: 1px solid black;
    font-size: 8.5px;
    width: 20%;
}
.WPQTable1 th {
    padding-left: 3px;
    /* text-align: left; */
    background: var(--col-ddd);

    font-family: "GE-BodyCopy";
    font-style: normal;
    border-collapse: collapse;
    font-weight: normal;
    border: 1px solid black;
    font-size: 8.5px;
    width: 20%;
}
.WPQTable2 th {
    padding-left: 1px;
    text-align: left;
    background: var(--col-ddd);

    font-family: "GE-BodyCopy";
    font-style: normal;
    border-collapse: collapse;
    font-weight: normal;
    border: 1px solid black;
    font-size: 8.5px;
    width: 20%;
}

.WPQtablenoborder {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    width: 80%;
    border: none;

    vertical-align: top;
    padding: 0%;

}

.WPQtablenoborder td th {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;

    vertical-align: top;
    height: 1px;
}

.WPQgodrejTitle {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0px;
    padding: 0
}

.WPQgodrejAddress {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding: 0;
}

.WPQpageTitle {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 010px;
    font-style: italic;
    font-weight: bolder;
    padding: 0;
}

.WPQright-td-border {
    border-bottom: solid black;

}

.WPQbasemetal {
    font-family: "GE-BodyCopyHighlight";
    font: "GE-BodyCopyHighlight";
     font-weight:900;
     font-style: bold;
    font-size: 9px !important;
    padding-top: 0%;
    /* width: 10%; */
    padding-bottom: .2%;
    line-height: 1;
    white-space: nowrap;
}

.WPQtitle5 {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-weight: 700;
    font-size: 9.0px;
    padding-top: 0%;
    /* width: 23%; */
    padding-bottom: .2%;
    line-height: 1;
}
.Noteswrap{
    word-wrap: break-word !important;
    width: 100px !important;
}
.WPQthickness {
    text-align: left;
    width: 32%;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-weight: 900;
    font-size: 8.5px;
    align-items: left;
    padding-left: 1px;
    letter-spacing: 0.00px;
    padding-top: 0%;
    padding-bottom: .2%;
    line-height: 1;

}

.WPQempty {
    width: 1%;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}

.WPQemptyTitle {
    width: 14%;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}

.WPQmesursment {
    padding-left: 1px;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    text-align: center;
    font-size: 8.5px;
    padding-bottom: 0%;
    padding-top: 0%;
    line-height: 1;
}
.div1class{
   width:100%;
   text-align: center;
   font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
}

.div2class{
    width:50%;
    text-align: center;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
 }

 .div3class{
    width:35%;
    text-align: center;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    word-break: keep-all; 
    word-break: break-all;
    /* word-wrap: break-word;
        word-break: break-all; 
    /* white-space: "nowrap"; */
 }
 
 

.divRightleftBorder{
    border-left: 1px dashed black;
    border-right: 1px dashed black
 }
.RightBottom{
    border-bottom: 1px solid black;
    border-right: 1px solid black
 }
.RightleftBottom{
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
 }
.divLeftBorder{
    border-left: 1px dashed black
 }

.WPQTable7 {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-weight: 900;
    font-style: bold;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    width: 72.6%;

}

.WPQTable7 th {
    padding-left: 1px;
    text-align: left;
    background: var(--col-ddd);
    padding-top: 0%;
     border-right: 1px solid black;
    border-left: 1px solid black; 
    font-weight: 900;
    font-style: bold;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    font-style: normal
}

.WPQTable7 td {
    font-weight: 900;
    font-style: bold;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.WPQTable8 {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    width: 570px;

}

.WPQTable8 th {
    padding-left: 1px;
    text-align: left;
    background: var(--col-ddd);
    padding-top: 0%;
     /* border-right: 1px solid black; */
    border-left: 1px solid black; 
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    font-style: normal
}

.WPQTable8 td {

    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
}






.WPQrcorner {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    float: right;
}

.WPQmid {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-left: 1px;

}

.WPQmidGas {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    padding-left: 1px;
}

.WPQlcorner {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    float: left;
    padding-left: 0%;

}

.WPQgroupcols {
    display: flex;
    align-self: center;
    
    flex-direction: row;
    flex: 1 1 1
}

.WPQlcorner3cols {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    float: left;
    padding-left: 0%;
    width: 30%;

}

.WPQrcorner3cols {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    float: right;
    width: 30%;
    align-items: center;

}

.WPQmid3cols {
    font-family: "GE-BodyCopy";
    font: "GE-BodyCopy";
    font-size: 8.5px;
    width: 30%;

}

.WPQTable7 tr:nth-child(even) {
    background-color: var(--bg-alt-2);
}

.WPQTable7 tr:nth-child(odd) {
    background-color: white;
}

.WPQTable7 tr td:last-child {
    border-right: 1px solid black;
}

.WPQTable8 tr:nth-child(even) {
    background-color: var(--bg-alt-2);
}

.WPQTable8 tr:nth-child(odd) {
    background-color: white;
}

.WPQTable8 tr td:last-child {
    border-right: 1px solid black;
}

.WPQTable7 tr:last-child{
    /* border-bottom: 1px solid black; */
}

.WPQimgsizebig {
    height: 1px;
    width: 1px
}

.WPQprocessleft {
    word-break: break-all;

}
