.WPQTTStable{
    width: 570px;
    border: "1px solid black";
    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family:"GE-BodyCopyHighlight";
    font-style: bold;
    font-weight: 900;
}

.Noteswrap{
    vertical-align: text-top;
}

.Noteswrap{
    vertical-align: text-top;
}

.WPQfooter1{
    

    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    font:"GE-BodyCopy";
       font-size:  11px;
    font-weight: bold;
    table-layout: fixed;
    /* width: 71%; */
    width:570px;
}
.WPQfooter1 td{
    

    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: .1px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: right;
    font:"GE-BodyCopy";
        font-size:  10px;
    
}


.WPqttpageTitle{
    font-family:"GE-BodyCopy";
    font:"GE-BodyCopy";
    font-size:  14px;
    font-style: italic;
    font-weight: bolder;
    padding: 0;
}
.WPQfooterRow1{
    order: 3;
    align-items: flex-end;
    flex-grow: 0;
    /* position: relative;
    top: 100px; */
    /* position: absolute;
    bottom: 10px; */
 }

.WPQTTStable td {
    border-right: "1px solid black";
    border-left: "1px solid black";
    border-bottom: none;
    border-top: none;
    font-family:"GE-BodyCopyHighlight";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.WPQTTStable th {
    border-right: none;
    border-left: "1px solid black";
    border-bottom: none;
    padding-left: 1px;
    border-top: none;
    text-align: left;
    background: var(--col-ddd);
    font-family:"GE-BodyCopyHighlight";
    font-style: normal;
    font-weight: normal;
    font-size: 8.5px;
    width: 25%;
}
.WPQTTStable tr:last-child{
    border-bottom: "1px solid black";
}
.WPQTTStable tr:first-child{
    border-top: "1px solid black";
}
.WPQTTStable tr td:last-child{
    border-right: "1px solid black";
}
.pdfLeftBorder{
    border-left:"1px solid black";
}
.pdfRightBorder{
    border-right:"1px solid black";
}
.WPQTTSImagetable{

    width: 72%;
    border: "1px solid black";

    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family:"GE-BodyCopy";
    font-style: normal;
   

   
}

.WPQTTSImagetable td {
   
    border-left: "1px dashed black";
    border-bottom: none;
    border-top: none;
    font-family:"GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
    height: 30px;
}
.pdfWPQLeftdashedborder{
    border-left: "1px dashed black";
    font-family:"GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;

}
.pdfWPQRightdashedborder{
    border-right: "1px dashed black";
    font-family:"GE-BodyCopy";
    font-style: normal;
    font-size: 8.5px;

}
.WPQTTSImagetable th {
    border-right: none;
    border-left: "1px solid black";
    border-bottom: none;
    padding-left: 1px;
    border-top: none;
    text-align: left;
    background: var(--col-ddd);
    font-family:"GE-BodyCopy";
    font-style: normal;
    font-weight: normal;
    
    font-size: 8.5px;
    width: 34%;
}
.WPQTTSImagetable tr:last-child{
    border-bottom: "1px solid black";
}
.WPQTTSImagetable tr:first-child{
    border-top: "1px solid black";
}
.WPQTTSImagetable tr td:last-child{
    border-right: "1px solid black";
}
.WPQtt1{
    width: 80px;
   
    align-content: center;
    align-self:left;
    font-family:"GE-BodyCopy";
    font-style: normal;
    display: inline-block;
    border-right: "1px solid black";
    
    
}
.WPQtt2{
    width: 80px;
    align-self:left;
    align-content: center;
    font-family:"GE-BodyCopy";
    font-style: normal;
}



.WPQbasemetal {
    font-family:"GE-BodyCopyHighlight";
    font:"GE-BodyCopyHighlight";
    font-weight: 900;
    font-style: bold;
    font-size: 9px !important;
    padding-top: 0%;
    /* width: 23%; */
    padding-bottom: .2%;
    line-height: 1;
    white-space: nowrap;

}

.WPQbasemetalreport {
    font-family:"GE-BodyCopy";
    font:"GE-BodyCopy";
    font-weight: 700;
    font-size: 7.0px !important;
    padding-top: 0%;
    width: 23%;
    padding-bottom: .2%;
    line-height: 1;
}







.valuefontweight{
    font-weight: 500;
}
.WPQbasemetal1 {
    font-family:"GE-BodyCopy";
    font:"GE-BodyCopy";
    font-weight: 700;
    font-size: 9.0px;
    padding-top: 0%;
    width: 5%;
    padding-bottom: .2%;
    line-height: 1;
}



.PdfNoTopBorder{
    border-top: none !important;
}

.PdfNoLeftBorder{
    border-left: none !important;
}
.PdfNoRightBorder{
    border-right: none !important;
}