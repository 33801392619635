
/* Pagination CSS */

.pagintion-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1%;
    height: 2rem;
    font-size: 12px;
    color: var(--col-707070);    
    padding: 1%;
  }
  
  .pagintion-bar svg {
    cursor: pointer;
    color: var(--txt-color);
    margin: 2px 3px;
  }
  
  .pagintion-bar .disabled {
    pointer-events: none;
    color: var(--inactive-gray);
  }
  
  .prevNext{
    --bs-btn-padding-y: none;
    font-size: 12px;
  }

  .fontCss{    
    font-size: 12px;
  }
  
  .fontCssPage{    
    font-size: 12px;
    margin-bottom: 1rem;
    padding-right: 1rem;
  }

  .resetBtndev {
    text-decoration: none;
    color: black;
    padding-left: 0px !important;
  }

  .pagintion-bar *{
    margin-right: 0px;
  }

  .verticalLine{
    border-left: 1px solid var(--col-707070);
    height: 18px;    
  }

  /* Pagination Ends */
  
  