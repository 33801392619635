.column {
    float: left;    
    width: 33.33%;
    /* padding-left: 5%; */
    padding-left: 1.3rem;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
    line-break: normal;
  }
  .mnlabel{
    font: 'GE-BodyCopy',sans-serif, Regular;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
    font-weight: bold;
  }
  .mnlabel :checked{
    background-color: var(--godrej-green);
    border-color: var(--godrej-green);
  }

  .sblabel{
    font: 'GE-BodyCopy',sans-serif, Regular;
    font-size: 12px;
    letter-spacing: px;
    opacity: 1;
    margin-left: 10%;
    
  }
  .sblabel :checked{
    background-color: var(--godrej-green);
    border-color: var(--godrej-green);
  }