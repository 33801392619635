@import "../../../../Assests/font/fontGE.css";
.WPStrhide{
    border-style: hidden;
    border-right: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
} 
.PQRcertifont{
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-size: 7.5px;
    width: 570px;
}
.WPSthhide{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 30%;
}

.PQRMainPDF{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    padding-top: 0;
    background-color: white;
    letter-spacing:0.01px;
    width: 793px;
    height: 830px;
  
}
.alignLeft{
    text-align: left; 
}
.PQRaligncenter{
    align-items: center;
    align-content: center;
    text-align: center;
}
.PQRpdfLeftBorder {
    border-left: 1px solid black;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-weight: normal;
}
.TTsfoot_noBotm_noLft{
    border-bottom: none;
    border-left: none;

}
.TTSimg{
   height:30%; 
   width: 30%;
}
.Table2Th.th{
    border-right: .4px solid black ;
    border-top:.11px solid black
}

.PQRcontainer{

    display: flex;
    height: 100%;
}


.PQRgroup{
    display: flex;
    align-items: left;
 
}
.PQRflexb{
    display: flex;
    flex-direction: column;
   
    align-items: stretch;
}
.PQRheaderRow{
    order: 1;
    flex-grow: 0;
    padding-top: 1px;
}
.PQRContent{
    order: 2;
  
    flex-grow: 1;
   
}
.PQRfooterRow{

   position: relative;
   top: 210px;
}

.PQRfooterttpg2{
    position: relative;
    top: 160px;
}

.PQRfooterttpg3{
    position: relative;
    top: 120px;
}

.PQRvl{
    border-left: 1px solid black;

}
.PQRnoborder{
    border-bottom: none;
    border-top: none;
    border-left: none;
    background-color: white;
}
.PQRSignatureTable{
    
    font-size:  9px;
}

.allth.th{
    text-align: center;
}

 .tab2tH.th{
    /* border-top:1px solid black; */
    /* border-bottom: 1px solid black; */
    border-right: 1px solid black;  
    /* border-left: .11px solid black;    */
    
}
.tab2tH.td{
    border-top: 1px solid black;
    /* border-bottom: 1pxsolid black; */
    border-right: 1px solid black;   
    /* border-left: 1px solid black;    */
    
}

.WPSfooter td{
    

    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: .1px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: right;
    font-family:"GE-BodyCopyHighlight";
    font-weight:900;
    font-style: bold;
        font-size:  9px;
    
}

.PQRTable1{
    width: 570px;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    table-layout: fixed;
    padding-top: 0%;
    padding-bottom: 0%;
    border:none;
 }

 .PQRTable1 td{

    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
        font-size: 7.6px;


}

.PQRTable1 th{
    
    text-align: left;
    background: var(--col-ddd);
    
    font-family: "GE-BodyCopy",sans-serif;
    font-style:normal;

    font-weight:normal;

        font-size: 7.6px;
    width: 20%;

  padding-left: 3px;
}


.PQRtablenoborder{
    font-family: "GE-BodyCopy",sans-serif;
    
    width: 80%;
    border: none;
    padding: 0;
    vertical-align: top;
    padding: 0%;
    
}
.PQRtablenoborder td th{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3%;
    padding-top: 0%;
    padding-bottom: 0%;

    vertical-align: top;
    height: .05px;
}
.PQRgodrejTitle{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size:  12px;
    font-weight: bold;
    letter-spacing:0.00;
    padding:0;
    text-align: center;
}
.PQRgodrejAddress{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size:  11px;
    padding: 0;
}
.PQRpageTitle{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size:  11px;
    font-style: italic;
    font-weight: bolder;
    padding: 0;
}
.PQRright-td-border{
    border-bottom: solid black;

}
.WPSfooter{
    font-family: "GE-BodyCopy",sans-serif;
    font-size:  11px;
    table-layout: fixed;
    width: 570px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    font-weight:900;
    font-style: bold;
}

 .PQRbasemetal{
     font-family: "GE-BodyCopyHighlight";
     font-weight:900;
     font-style: bold;
     font-size: 8.0px;
     padding-top: 0%;
     width: 45% ;
     padding-bottom: .2%;   
     line-height: 1;
     
}

 .PQRPthickness {
     text-align: left;
     width: 18.5%;
     font-family: "GE-BodyCopy",sans-serif;
     
     font-weight:900;
         font-size: 8.5px;
     align-items: left;
     padding-left: 5.5px;
     letter-spacing:0.00px;
     padding-top: 0%;
     padding-bottom: .2%;
     line-height:1;

 
}
.PQRempty{
    width: 6%;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
.PQRemptyTitle{
 
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 8px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
 .PQRmesursment {
    padding-left: .8px;
     font-family: "GE-BodyCopy",sans-serif;
     
     text-align: left;
            font-size: 8.5px;
     padding-bottom: 0%;
     padding-top: 0%;
     line-height: 1;
   }

 .PQRtitle5 {
     font-family: "GE-BodyCopy",sans-serif;
     
    text-align: left;     
           font-size: 8.5px;
    text-align: right;
    font-style: normal;
    font-weight: bold;
    padding-top: .1%;

    padding-bottom: 0%;
    line-height: 1;
    }
.PQRtitle4{
        font-family: "GE-BodyCopy",sans-serif;
        
        padding-left: 2px;
       text-align: right;     
              font-size: 7.6px;
       text-align: right;
       padding-top: .1%;

       padding-bottom: 0%;
       line-height: 1;
       }

.BaseMetalTh{
     border-right:1px solid black ;
     border-bottom:1px solid black ;
     height: 1px;
     text-align: center;
    font-family: "GE-BodyCopy",sans-serif;
    vertical-align: top;
    font-weight:normal;font-size: 7.6px;
       }

.PQRTable2{
    /* border: none; */
    width: 570px;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    padding: 0;
    border-collapse: collapse;
    row-gap:0;
   
    
}

.PQRTable2 td
{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    font-style: normal;
    vertical-align: top;
    row-gap:0;
    height: auto;
    border-collapse: collapse;
    padding-top: 0%;
    text-align: center;
    padding-right: 0%;
    white-space: nowrap;
      
}
pdfwpqTable {
 
 
    width: 570px;
    border-collapse: collapse;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    table-layout: fixed;
    font-size: 7.6px;
}

.pdfwpqTable td {
    /* border-right: none; */
    
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}

.pdfwpqTable th {
    /* border-right: none; */
    
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-weight: normal;
    
    font-size: 7.6px;
    width: 34%;
}
.PQRCertificationTable{
    width: 269px ;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    padding: 0;
    border-collapse: collapse;
   
}

.PQRCertificationTable td
{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    font-style: normal;
    vertical-align: top;
    row-gap:0;
    /* width:"30px"; */
    height: auto;
    border-collapse: collapse;
    padding-top: 0%;
    text-align: center;
    padding-right: 0%;
      
}
.PQRrcorner{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    float: right;
    text-align: right;
}
.PQRTablePg2{
    border: none;
    width: 570px;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    padding: 0;
    border-collapse: collapse;
}

.PQRTablePg2 td
{
    padding-left: 3px;
    font-family: "GE-BodyCopy",sans-serif;
    
     font-size: 7.6px;
    font-style: normal;
    text-align: center;
      vertical-align: top;
    height: auto;
    border-collapse: collapse;
      
}
.PQRTTSpg3{
    border: none;
    width: 570px;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    padding: 0;
    border-collapse: collapse;
}

.PQRTTSpg3 td
{
    padding-left: 3px;
    font-family: "GE-BodyCopy",sans-serif;
    
     font-size: 7.6px;
    font-style: normal;
    text-align: left;
      vertical-align: top;
    height: auto;
    border-collapse: collapse;
      
}
.PQRTTSpg3 th{
   
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
   vertical-align: top;
   font-weight:normal;
   font-size: 7.6px;
   padding-left: 3px;
   border-collapse: collapse;
   /* border: none; */
}
.PQR_border{
    border-right: 1px solid black ; 
    border-left: 1px solid black ;
    border-bottom: 1px solid black ;  
    border-top: 1px solid black ;  
}
.PQR_noRgt{
    border-left: 1px solid black ;
    border-bottom: 1px solid black ;  
    border-top: 1px solid black ;  
}
.PQR_noleft{
    border-right: 1px solid black ; 
    border-bottom: 1px solid black ;  
    border-top: 1px solid black ;  
}
.PQR_noleft_topDot{
    border-right: 1px solid black ; 
    border-bottom: 1px solid black ;  
    border-top: 1px dotted black ;  
}
.PQR_right{
  border-right: 1px solid black ;
}
.PQRleft{
  border-right: 1px solid black ;
}
.PQR_Topleft{
  border-left: 1px solid black ;
  border-top: 1px solid black ;
}
.PQR_Toprt{
  border-right: 1px solid black ;
  border-top: 1px solid black ;
}
.PQR_Botm{
    border-bottom: 1px solid black ; 
}
.PQR_Botmlft{
    border-bottom: 1px solid black ; 
    border-left: 1px solid black ; 
}
.PQR_Botmrt{
    border-bottom: 1px solid black ; 
    border-right: 1px solid black ; 
}
.PQR_topBotm{
    border-bottom: 1px solid black ; 
    border-top: 1px solid black ; 
}
.PQR_rt_lft{
    border-right: 1px solid black ;   
    border-left: 1px solid black ;   
}
.PQR_noBotm{
    border-right: 1px solid black ;   
    border-top: 1px solid black ;   
    border-left: 1px solid black ;   
}
.PQR_rt_botm{
    border-right: 1px solid black ;   
    border-bottom: 1px solid black ;   
}
.PQR_noTop{
    border-right: 1px solid black ; 
    border-bottom: 1px solid black ;  
    border-left: 1px solid black ; 
}
.PQR_noTopnoLft{
    border-right: 1px solid black ; 
    border-bottom: 1px solid black ;  
  
}

.topBotm_col1{
border-bottom: 1px solid black ; 
border-top: 1px solid black ; 
text-align: center;
word-break: break-word; 
max-width: fit-content;
border-bottom: none;
border-top: none;
border-left: none;
font-family: "GE-BodyCopy",sans-serif;

font-size: 7.6px;}

.PQRTable2 th{
   word-break: keep-all;
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
   vertical-align: top;
   font-weight:normal;
   font-size: 7.6px;
   border-collapse: collapse;
   padding-top: 0%;
   row-gap:0;
   padding-bottom: 0%;
}
.PQRCertificationTable th{
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
   vertical-align: top;
   font-weight:normal;
   font-size: 7.6px;
   border-collapse: collapse;
   padding-top: 0%;
   row-gap:0;
   padding-bottom: 0%;
}
.PQRTablePg2 th{
   
     text-align: center;
     background: var(--col-ddd);
     font-family: "GE-BodyCopy",sans-serif;
    vertical-align: top;
    font-weight:normal;
    font-size: 7.6px;
    padding-left: 3px;
    border-collapse: collapse;
}
.table2bottomboder{
    border-bottom:  none;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    
}
.PQRcorner {
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 8.5px;
    float: right;
}
.PQRtable2rightborder{
    border-top: 1px solid black;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    }
.PQRtable2withbottomboder{
    border-bottom:  .11px solid black;
}

.PQRbmTh{
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-left:  1px solid black;
    border-bottom:  1px solid black;
}
.PQRtable2firstcolumn{
    width: 25%;
    border-right: none;
    border-top: none;
    
    font-size: 7.6px;
}
.PQRTable3{
    font-family: "GE-BodyCopy",sans-serif;
    width: 70%;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.PQRmin{
           
    text-align: center;
    
}
.PQRmax{

    text-align: center;
      
}

.PQRtable-3 {
    width: 55%;
    border: none;
    font-family: "GE-BodyCopy",sans-serif;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}
.PQRtable-3 th{
    font-family: "GE-BodyCopy",sans-serif;
    padding: .1px;
    border-collapse:collapse;
    
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    width: 50%;

}    
.PQRtable-3 td{
    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    border: none;
    border-top: none;
    border-bottom: none;
    text-align: center;
    
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.PQRtable3-th{
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-weight: normal;
    background: var(--col-ddd);
    border-top: none;
    border-bottom: none;
    border-right:  .13px solid black;
    border-left: .13px solid black;
    border-top: .1px solid black;
    width: 50%;
}
.PQRtable3-th-left{
    text-align: left;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    font-weight: normal;
    background: var(--col-ddd);
    border-top: none;
    border-bottom: none;
    border-right:  .11px solid black;
    border-left: .11px solid black;
}
.PQRtable3-th{
    font-family: "GE-BodyCopy",sans-serif;
}
.PQRtable3-thwithboder{
    text-align: left;
    font-weight: normal;
    background: #CCCCCC;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    background: var(--col-ddd);
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: .11px solid black;

}
.PQRtable3-thwithbtboder{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: .11px solid black;
    border-left: .11px solid black;
           font-size: 7.6px;
  
}
.PQRtable3-topth{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .1px solid black;
    border-left: .11px solid black;
    text-align: center;
           font-size: 7.6px;
  
}
.PQRtable3-topth1{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: .1px solid black;
    text-align: center;
           font-size: 7.6px;
  
}
.PQRtable3-bottomth{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    text-align: center;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: .1px solid black  ;
    border-left: none;
           font-size: 7.6px;
  
}
.PQRtable3-sideth{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    text-align: center;
    border-left: .1px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
  
}
.PQRtable3-sideth1{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    text-align: center;
    border-left: .11px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
    
  
}
.PQRtable3-siderth{
    text-align: left;
    font-weight: normal;
    background: var(--col-ddd);
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    text-align: center;
    border-right: .11px solid black;
    border-top: none;
    border-left: none;
    border-bottom: .11px solid black;
           font-size: 7.6px;
  
}
.PQRt3-td-text{
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
}
.PQRtable-3-tdborder{
   font-family: "GE-BodyCopy",sans-serif;
   border-bottom: .11px solid black;
}

.PQRtable-4{
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    
    table-layout: fixed;
}
.PQRtable-4 td th{
    border: .1px solid black;
    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.PQRleft-td{
    border-top: .1px solid black;
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
    
}
.PQRleft-td-nobottom{
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-bottom: none;
    border-top: none;
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
}


.PQRleft-td-nobottomnoleft{
    border-left: none;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    border-top: none;
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
}
.PQRNoLeft{
    border-left: none;
    border-right: .1px solid black;
    border-bottom: .1px solid black;
    border-top: none;
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
}
.PQRleft-td-nobottomtop{
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-bottom: none;
    border-top: none;
    border-top: .1px solid black;
    font-family: "GE-BodyCopy",sans-serif;
           font-size: 7.6px;
}
.PQRright-td{
    border-top: .11px solid black;
    border-left: .11px solid black;
    border-right: .11px solid black;
    
}
.PQRright-td1{

    border-right: .11px solid black;
    width: 2%;
}
.PQRTable6 {
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.PQRTable6 td{
   
    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    text-align: center;
    padding-bottom: 0%;
}
.PQRTable6 th{padding-left: 3px;
    text-align: center;

    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
           font-size: 7.6px;
  
    padding-top: 0%;
    padding-bottom: 0%;
}
.PQRleft-th-nobottomnoright{
    border-right: none;
    border-top: none;
    border-left: .1px solid black;
    border-bottom   : .1px solid black;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;

}
.PQRleft-th-nobottom{
    border-left: .1px solid black;
    border-right: none;
    border-top: none;
    border-bottom: none;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;

}
.PQRleft-th-noleft{
    border-left: none;
    border-top: 1px solid black;
    border-right: 1px solid black;
    background-color: var(--col-ddd);
    border-bottom: 1px solid black;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
           width: 15%;

}
.PQRleft-th-notop{
    border-left: .1px solid black;
    border-right: .1px solid black;
    border-top: none;
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;

}
.left-th{
   /* border:none; */
    background-color: var(--col-ddd);
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    padding-bottom: 0%;
}
.PQRforfont{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
           text-align: center;
}
.PQRforfontJD{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
           text-align: left;
}

.PQRTable7{
    
    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
   width: 72.6%;
   
}
.PQRTable7 th{padding-left: 3px;
text-align: left;
background: var(--col-ddd);
padding-top: 0%;
border-right: .1px solid black; 

}
.PQRTable7 td{
    
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
           font-size: 7.6px;
    padding-left: 3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.PQRthhide{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 32%;
    white-space: nowrap;
}
.PQRfillerMetalth{
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    width: 154px ;
    white-space: nowrap;
    padding-left: 1px;    
}


.PQRtdhidden{
    visibility: hidden;
    width: 0%;
    border: none;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
}
   
.PQRside{
    width: 25%;
}

.PQRthhide1{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-top: .1px solid black;
    border-bottom: none;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    font-style:normal;
    font-weight: 500;

}
.PQRthhidelast{
    border-style: hidden;
    border-right: .1px solid black;
    border-left: .1px solid black;
    border-bottom: .1px solid black;
    border-top: none;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;
    font-style:normal;
    font-weight: 500;
    
}
.PQRleft7-td-noleft{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
    border-left: none;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 7.6px;   
    width: 189px !important;
}


.PQRleft7-td-noright1{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-bottom: none;
    border-top: none;
   border-right: none;
   font-family: "GE-BodyCopy",sans-serif;
   
   font-size: 7.6px;
   border-left: .1px dashed black;
   border-right: none;
   z-index: 10;
   width: 189px !important ;
}
.PQRleft7-td{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
   border-right: none;
   font-family: "GE-BodyCopy",sans-serif;
   
   font-size: 7.6px;
   width: 189px !important ;
}

.PQRleft7-td-noright{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-bottom: none;
    border-top: none;
   border-right: none;
   font-family: "GE-BodyCopy",sans-serif;
   
    font-size: 7.6px;
    width: 189px !important ;
   border-left: .1px dashed black;

}
.PQRleft7-td{
    border-right: .1px dashed black;
    border-left: .1px dashed black;
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
           z-index: 10;

   
}
.PQRrcorner{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    float:right;
}
.PQRmid{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    padding-left: 2px;

}
.PQRmidGas{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    padding-left: 2px;
    text-align: center;
}
.PQRlcorner{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    float: left;
    padding-left: 0%;
 
}
.PQRgroupcols{
    display: flex;
    align-self: center;
    align-items: center;
   
}
.PQRlcorner3cols{
    font-family: "GE-BodyCopy",sans-serif;
    
       font-size: .5px;
    float: left;
    word-wrap: break-word;
    text-align: center;

}

.PQRlcorner3colstube{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    float: left;
    word-wrap: break-word;
    text-align: center;
    position: relative;
    right: 30px;

}
.PQRrcorner3cols{
    font-family: "GE-BodyCopy",sans-serif;
    
       font-size: .5px;
    float:right;
    text-align: center;
    word-wrap: break-word;
    position: relative;
    left: 10px;

    /* align-items: center; */

}
.PQRrcorner3colstube{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
    float:right;
    text-align: center;
    word-wrap: break-word;
    width: 10px;
    /* position: relative;
    right: 90px; */

    /* align-items: center; */

}
.PQRmid3cols{
    font-family: "GE-BodyCopy",sans-serif;
    
       font-size: .5px;
       text-align: center;
       word-wrap: break-word;
       position: relative;
       left: 6px;

       /* text-align: right; */


}

.PQRmid3colstube{
    font-family: "GE-BodyCopy",sans-serif;
    
           font-size: 7.6px;
       text-align: center;
       word-wrap: break-word;
       position: relative;
       left: 180px;

       /* text-align: right; */


}
.PQRTable7 tr:nth-child(even) {
    background-color:var(--bg-alt-2);
    /* z-index: 10; */
    
    
}
.processleft1{
    background-color: transparent;
    background: none;
}

/* .process1{
    width: 10%;
    text-align: center;
} */
.process12{
    width: 70%;
    text-align: center;
 
}



.process123{
    width: 150px;
    text-align: center;
  
}







.PQRTable7 tr:nth-child(odd) {
    background-color: white;
    
    
}


.PQRborderhidden{
    border-bottom-style: hidden;
}

.PQRimgsize{
    position: absolute;

margin-left: 2.5%;   
margin-top: 1px;
 overflow-wrap: break-word;
    z-index: 0;


}

.PQRimgsizebig{
    width: 95%;
    height: 95%;
     overflow-wrap: break-word;
        z-index: 0;

}
.PQRtrhide{
    /* border-right: 1px solid black; */
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
} 
.weldedCentre{
    position: relative;
   top:0;
   bottom: 2px;
    /* width: 100%; */
    text-align: center;
}

.PQRpwhtTable{
    /* width: 72.6%; */
    width: 570px;
    border-collapse:collapse;
    border: "1px solid black";
    
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
    table-layout: fixed;
    
}

.PQRpwhtTable td{
    
    border-collapse:collapse;
    font-family: "GE-BodyCopy",sans-serif;
    font-style: normal;
           font-size: 7.6px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    
}
.PQRpwhtTable th{
    padding-left: 3px;
    text-align: left;
    background: var(--col-ddd);
    padding-bottom: 0%;
    font-family: "GE-BodyCopy",sans-serif;
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border-bottom:  1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
           font-size: 7.6px;
    /* width: 18%; */
}
.PQRTable8 {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    width: 570px;

}
.PQRTable8 tr:nth-child(even) {
    background-color: var(--bg-alt-2);
}

.PQRTable8 tr:nth-child(odd) {
    background-color: white;
}

.PQRTable8 tr td:last-child {
    border-right: 1px solid black;
}

.PQRTable8 th {
    padding-left: 1px;
    text-align: left;
    background: var(--col-ddd);
    padding-top: 0%;
     /* border-right: 1px solid black; */
    border-left: 1px solid black; 
    font-family: "GE-BodyCopy",sans-serif;
    
    font-size: 8.5px;
    font-style: normal
}

.PQRTable8 td {

    font-family: "GE-BodyCopy",sans-serif;
    
    font-style: normal;
    font-size: 8.5px;
    padding-left: 1px;
    padding-top: 0%;
    padding-bottom: 0%;
}


.process3{
    /* width: 200px; */
    text-align: center;
   /* background-color: saddlebrown; */
}

.process2{
    /* width: 200px; */
    text-align: center;
    /* background-color: mediumorchid; */
}
.process1{
    /* width: 200px; */
    text-align: center;
    /* background-color: teal; */
}
