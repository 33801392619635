@import "../../../Assests/font/fontGE.css";

@font-face {
    font-family: "NotoSansDevanagari-Regular" ;
    src: 
    /* url("../font//Rajdhani-Regular.woff2") format('woff2'), */
    url("../font/NotoSansDevanagari-Regular.ttf") format("truetype");
    font-style: normal;
     font-size: 7.6px;       
}

.footer td{
    
    border-collapse:collapse;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top:  8px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: right;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    
}

/* .td .th {
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
} */
.Table50{
    width: 72%;
    border: 1px solid black;
    border-collapse: collapse;


}



.Table50 th {
    border: 1px solid black;
    border-collapse: collapse;
    width: 10%; 
    text-align: left;
  }

  

.wider-cell1 {
    /* width: 25%;  */
    text-align: center; 
  }



.wider-cell {
    /* width: 1%;  */
    text-align: left; 
  }
  
  

  .wider-cell2 {
    /* width: 55%;  */
    text-align: left; 
  }

  
  .wider-cell3 {
    /* width: 40% !important;  */
    text-align: left; 
  }


  .tableheading1{
    /* position: relative; */
    font-size: 100px;
    /* text-align: left; */
    width: 100px;
    z-index: 10;
}


.tableheading{
    position: relative;
    font-size:  12px;
    text-align: center;
    /* border-left: 1px solid black; */
    vertical-align: text-top !important;
    font-family: GE-BodyCopyHighlight;
    font: GE-BodyCopyHighlight;
    font-weight: 900;
    font-style: bold;
}

.noright{
    border-right: 0px;
}
.tableheading2{
    position: relative;
    font-size:  12px;
    text-align: center;
    /* border-left: 1px solid black; */
    vertical-align: text-top !important;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-weight: 100 !important;
}

.tableheadingd{
    position: relative;
    font-size:  12px;
    text-align: left;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;  
    border-left: 1px solid black;
    /* border-left: 1px solid black; */
}

.widdd{
    width: 2% !important;
}

.tableheadingL{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    position: relative;
    font-size:  12px;
    text-align: left;
    /* border-left: 1px solid black; */
    /* border-left: 1px solid black; */
}

.tableheadingdata{
    position: relative;
    font-size:  12px;
    text-align: center;
    border-left: 1px solid black;
}
.tabledata{
    font-size:  12px;
    text-align: left;
    font-family: GE-BodyCopyHighlight;
    font: GE-BodyCopyHighlight;
    font-weight: 900;
    font-style: bold;
}
.tabledatad{
    font-size:  12px;
    text-align: left;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    /* font-weight: 900;
    font-style: bold; */
}

.MainPDF{
    font-family: Rajdhani-Regular;
    font: Rajdhani-Regular;
    font-size:  12px;
    padding-top: 0;
    background-color: white;
    letter-spacing:0.01px;
    /* width: 793px; */
    width: 973px;


}




.Table50 th{
    background: white;

}

.tablenoborder{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    width: 80%;
    border: none;
    padding: 0px;
    vertical-align: top;
    padding: 0%;
    
}
.tablenoborder td th{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;

    vertical-align: top;
    height: .05px;
}
.godrejTitle{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  14px;
    letter-spacing:0.00px;
    padding:0;
    position: relative;
    bottom: 10px;
}
.godrejAddress{
    font-family: GE-BodyCopyHighlight;
    font: GE-BodyCopyHighlight;
    font-weight: 900;
    font-size:  12px;
    padding: 0;
}

.godrejAddressHindi{
    font-family: NotoSansDevanagari-Regular;
    font: NotoSansDevanagari-Regular;
    font-weight: 900;
    font-size:  12px;
    padding: 0;
}


.ReportHeadersleft{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    white-space: nowrap;
}
.ReportHeadersleftlong{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    width:72%;
    overflow-wrap: break-word;
    overflow: hidden;

}

.ReportHeadersleft1{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    /* inline-size: min-content; */
    /* overflow-wrap: break-word;
    overflow: hidden; */
}



.ReportHeadersright{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 230px;
}

.ReportHeadersrightProcess{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 110px;
}

.ReportHeadersrightProcesstts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 240px;
}

.ReportHeadersright1{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 119px;
}

.ReportHeadersright2pg2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 155px;
}

.ReportHeadersright1pg2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 35px;
}

.ReportHeadersright2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 80px;
}

.ReportHeadersright2tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 0px;
}


.ReportHeadersright3{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 200px;
}

.ReportHeadersright3tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 160px;
}

.ReportHeadersright4{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 187px;
}

.ReportHeadersright4tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 147px;
}

.Reportslipheadright{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 103px;
}

.Reportslipheadrightdate{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 390px;
}


.Reportdataleft{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 10px;
    inline-size: min-content;
    overflow-wrap: break-word;
    overflow: hidden;

}



.Reportdataleftnotes{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 0px;
    inline-size: min-content;
    overflow-wrap: break-word;
    overflow: hidden;
}

.notefonts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
}

.Reportdataright{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 240px;
}

.ReportdatarightProcess{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 115px;
}

.ReportdatarightProcesstts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 260px;
}

.Reportdataright1{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 135px;
}

.Reportdataright2pg2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 165px;
}


.Reportdataright1pg2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 45px;
}
.Reportdataright2{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 90px;
}
.Reportdataright2tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 10px;
}
.Reportdataright3{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 210px;
}

.Reportdataright3tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 170px;
}

.Reportdataright4{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 197px;
}

.Reportdataright4tts{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 157px;
}
.Reportslipnodata{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 113px;
}

.ReportHeadersmiddle{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 50px;
}

.Reportdatamiddle{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  12px;
    padding: 0;
    position: relative;
    left: 60px;
    inline-size: min-content;
    overflow-wrap: break-word;
    overflow: hidden;
}


.pageTitle{
    font-family: GE-BodyCopyHighlightItalic;
    font: GE-BodyCopyHighlightItalic;
    font-size:  16px;
    font-style: italic;
    font-weight: 900;
    padding: 0;
    position: relative;
    right: 20px;
}

/* .hr.borderline {
    border: 1px solid black;
    position: relative;
    right: 40px;
    bottom:none
  } */

  .borderline {
    bottom:none;
    width: 90%;
    height: 2px;
    /* margin: 5px 0 0 ; */
    background-color: black;
  }
.right-td-border{
    border-bottom: solid black;

}

 .basemetal{
     font-family: GE-BodyCopy,sans-serif;
     font: GE-BodyCopy,sans-serif;
     font-weight:900;
       font-size:  12px;
     padding-top: 0%;
     width: 23% ;
     padding-bottom: .2%;
     line-height: 1;
}
 .thickness {
     text-align: left;
     width: 32%;
     font-family: GE-BodyCopy,sans-serif;
     font: GE-BodyCopy,sans-serif;
     font-weight:900;
        font-size:  12px;
     align-items: left;
     padding-left: 9.8px;
     letter-spacing:0.00px;
     padding-top: 0%;
     padding-bottom: .2%;
     line-height:1;
 
}
.empty{
    width: 1%;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
.emptyTitle{
    width: 14%;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
    padding-bottom: 0%;
    line-height: 0;
    padding-top: 0%;
}
 .mesursment {
    padding-left: .8px;
     font-family: GE-BodyCopy,sans-serif;
     font: GE-BodyCopy,sans-serif;
     text-align: center;
        font-size:  12px;
     padding-bottom: 0%;
     padding-top: 0%;
     line-height: 1;
   }

 .title5 {
     font-family: GE-BodyCopy,sans-serif;
     font: GE-BodyCopy,sans-serif;
    text-align: right;     
       font-size:  12px;
    text-align: right;
    font-style: normal;
    font-weight: bold;
    padding-top: .1%;

    padding-bottom: 0%;
    line-height: 1;
    }
    .title4{
        font-family: GE-BodyCopy,sans-serif;
        font: GE-BodyCopy,sans-serif;
        padding-left: 2.4px;
       text-align: right;     
          font-size:  12px;
       text-align: right;
       padding-top: .1%;

       padding-bottom: 0%;
       line-height: 1;
       }

.Table2{
    width: 45%;


    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    padding: 0;
}

.Table2 td
{

    padding-left: 6px;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    border: none;
       font-size:  12px;
    font-style: normal;
 
    vertical-align: top;
    height: auto;
      
}

.Table2 th{
   
    text-align: left;
    font-family: GE-BodyCopy,sans-serif;
    border: none;

    vertical-align: top;
    font-weight:normal;

       font-size:  12px;
}
.table2bottomboder{
    border-bottom:  none;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
    border-bottom: 2px solid black;
    
}
.table2rightborder{
    border-right: .13px solid black;
    border-top: .15px solid black;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
    }
.table2withbottomboder{
    border-bottom:  .11px solid black;
}
.table2firstcolumn{
    width: 25%;
    border-right: .12px solid black;
    border-top: .14px solid black;
    border-left: .14px solid black;
    border-bottom: none;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.Table3{
    font-family: GE-BodyCopy,sans-serif;
    width: 73%;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.min{
           
    text-align: center;
    font:  GE-BodyCopy,sans-serif;
}
.max{

    text-align: center;
      
}

.table-3 {
    width: 55%;
    border: none;
    font-family: GE-BodyCopy,sans-serif;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}
.table-3 th{
    font-family: GE-BodyCopy,sans-serif;
    padding: .1px;
    border-collapse:collapse;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    width: 50%;

}    
.table-3 td{
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    border: none;
    border-top: none;
    border-bottom: none;
    text-align: center;
    
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.table3-th{
    text-align: left;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    font-weight: normal;
    border-top: none;
    border-bottom: none;
    border-right:  .13px solid black;
    border-left: .13px solid black;
    border-top:  8px solid black;
    width: 50%;
}
.table3-th-left{
    text-align: left;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    font-weight: normal;
    border-top: none;
    border-bottom: none;
    border-right:  .11px solid black;
    border-left: .11px solid black;
}
.table3-th{
    font-family: GE-BodyCopy,sans-serif;
}
.table3-thwithboder{
    text-align: left;
    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;

    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: .11px solid black;

}
.table3-thwithbtboder{
    text-align: left;
    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: .11px solid black;
    border-left: .11px solid black;
       font-size:  12px;
  
}
.table3-topth{

    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: 12px solid black;
    border-left: .11px solid black;
    text-align: center;
       font-size:  12px;
  
}
.table3-topth1{
 
    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    border-top: .11px solid black;
    border-bottom: none;
    border-right: .11px solid black;
    border-left: 12px solid black;
    text-align: center;
       font-size:  12px;
  
}
.table3-bottomth{
   
    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    text-align: center;
    border-bottom: .11px solid black;
    border-top: none;
    border-right: 12px solid black  ;
    border-left: none;
       font-size:  12px;
  
}
.table3-sideth{

    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    text-align: center;
    border-left: 12px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
       font-size:  12px;
  
}
.table3-sideth1{

    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    text-align: center;
    border-left: .11px solid black;
    border-top: none;
    border-right: none;
    border-bottom: .11px solid black;
       font-size:  12px;
    
  
}
.table3-siderth{

    font-weight: normal;
    background: white;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    text-align: center;
    border-right: .11px solid black;
    border-top: none;
    border-left: none;
    border-bottom: .11px solid black;
       font-size:  12px;
  
}



.t3-td-text{
    font-family: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.table-3-tdborder{
   font-family: GE-BodyCopy,sans-serif;
   border-bottom: .11px solid black;
}

.table-4{
    border: none;
    padding-top: 0%;
    padding-bottom: 0%;
    
    table-layout: fixed;
}
.table-4 td th{
    border: 12px solid black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.left-td{
    border-top:  8px solid black;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-bottom: 2px solid black;
    font-family: GE-BodyCopy,sans-serif;
       font-size:  12px;
    
}
.left-td-nobottom{
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-bottom: none;
    border-top: none;
    font-family: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.left-td-nobottomtop{
    border-right: 12px solid black;
    border-left: 12px solid black;
    border-bottom: none;
    border-top:  8px solid black;
    font-family: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.right-td{
    border-top: .11px solid black;
    border-left: .11px solid black;
    border-right: .11px solid black;
    
}
.right-td1{

    border-right: .11px solid black;
    width: 2%;
}
.Table6 {
    border: none;
    width: 72.6%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.Table6 td{
   
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.Table6 th{padding-left: 6px;
    text-align: left;

    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
  
    padding-top: 0%;
    padding-bottom: 0%;
}
.left-th-nobottomnoright{
    border-right: none;
    border-top: none;
    border-left: 12px solid black;
    border-bottom   : 12px solid black;
    background-color: white;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;

}
.left-th-nobottom{
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-top: none;
    border-bottom: none;
    background-color: white;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;

}
.left-th-notopnoleft{
    border-left: none;
    border-top: none;
    border-right: 12px solid black;
    background-color: white;
    border-bottom: 2px solid black;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;

}
.left-th-notop{
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-top: none;
    background-color: white;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;

}
.left-th{
    border-top:  8px solid black;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-bottom: 2px solid black;
    background-color: white;
    font-style: normal;
    font-weight: lighter;
    vertical-align: text-top;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.forfont{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.right-th{
    border-top:  8px solid black;
    border-left: 12px solid black;
    border-right: 12px solid black;
    background-color: white;
    font-family: GE-BodyCopy,sans-serif;
       font-size:  12px;
    border-collapse: collapse;
}
.Table7{
    border: 12px solid black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
   width: 72.6%;
   
}
.Table7 th{padding-left: .4px;
text-align: left;
background: white;
padding-top: 0%;
border-right: 12px solid black; 

}
.Table7 td{
    
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
}


.tdhidden{
    visibility: hidden;
    width: 0%;
    border: none;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
}
.trhide{
    border-style: hidden;
    border-right: 12px solid black;
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
    font-style: normal;
}
    
.side{
    width: 25%;
}
.thhide{
    border-style: hidden;
    border-right: 12px solid black;
    border-left: 12px solid black;
    border-bottom: none;
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    font-style:normal;
    font-weight: 500;
    width: 30%;
}

.thhide1{
    border-style: hidden;
    border-right: 12px solid black;
    border-left: 12px solid black;
    border-top:  8px solid black;
    border-bottom: none;
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    font-style:normal;
    font-weight: 500;

}
.thhidelast{
    border-style: hidden;
    border-right: 12px solid black;
    border-left: 12px solid black;
    border-bottom: 2px solid black;
    border-top: none;
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    font-style:normal;
    font-weight: 500;
    
}
.left7-td-noleft{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
    border-left: none;
    
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
   
}
.left7-td-noright1{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
   border-right: none;
   font-family: GE-BodyCopy,sans-serif;
   font: GE-BodyCopy,sans-serif;
      font-size:  12px;
   border-left: none;
}
.left7-td-noright{
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
   border-right: none;
   font-family: GE-BodyCopy,sans-serif;
   font: GE-BodyCopy,sans-serif;
      font-size:  12px;
   border-left: 12px dashed black;
}
.left7-td{
    border-right: 12px dashed black;
    border-left: 12px dashed black;
    text-align: center;
    word-break: break-word; 
    max-width: fit-content;
    border-style: dashed;
    border-bottom: none;
    border-top: none;
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
       font-size:  12px;
   
}
.rcorner{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    float:right;
}
.mid{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    padding-left: 2px;

}
.midGas{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    padding-left: 2px;
}
.lcorner{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    float: left;
    padding-left: 0%;
 
}
.groupcols{
    display: flex;
    align-self: center;
    align-items: center;
   
}
.lcorner3cols{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    float: left;
    padding-left: 0%;
    width: 30%;

}
.rcorner3cols{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
    float:right;
    width: 30%;
    align-items: center;

}
.mid3cols{
    font-family: GE-BodyCopy,sans-serif;
    font:  GE-BodyCopy,sans-serif;
       font-size:  12px;
       width: 30%;

}
.Table7 tr:nth-child(even) {
    background-color:var(--bg-alt-2);
    
    
}
.Table7 tr:nth-child(odd) {
    background-color: white;
    
    
}


.borderhidden{
    border-bottom-style: hidden;
}

.imgsize{
    position: absolute;

margin-left: 2.5%;   
margin-top: 1px;
 overflow-wrap: break-word;
    z-index: 0;


}

.imgsizebig{
    margin-left: 3%;   
    margin-top: 1px;
     overflow-wrap: break-word;
        z-index: 0;
        height: 7px;
        width: 7px


}
.Table8{
    width: 72%;
    border-collapse:collapse;
    border: 12px solid black;
    padding-top: 0%;
    padding-bottom: 0%;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
    table-layout: fixed;
    
}

.Table8 td{
    border: 12px solid black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    
}
.Table8 th{
    padding-left: 6px;
    text-align: left;
    background: white;
    
    font-family: GE-BodyCopy,sans-serif;
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border: 12px solid black;
       font-size:  12px;
    width: 20%;
}
.Table9 {
    border: none;
    width: 72.6%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}

.Table9 td{
    border-top: none;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-bottom: none;
}
.Table9 th{
    padding-left: 6px;
    text-align: left;
    background: white;
    
    font-family: GE-BodyCopy,sans-serif;
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border-top: none;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-bottom: none;
    font-size:  12px;
    width: 25%;
}

.Table10 {
    border: 12px solid black;
    width: 72.6%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
    font-family: GE-BodyCopy,sans-serif;
    font-size:  12px;
    height:150px
    
}
.Table10 td{
    
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
       vertical-align: top;
   
}
.Table11 {
    
    border: none;
    width: 72.6%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}
.Table11 td{
    border-top:  12px solid black;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-bottom:  12px solid black;
}
.Table11 th{
    padding-left: 6px;
    text-align: left;
    background: white;
    
    font-family: GE-BodyCopy,sans-serif;
    font-style:normal;
    border-collapse:collapse;
    font-weight:normal;
    border-top:  12px solid black;
    border-left: 12px solid black;
    border-right: 12px solid black;
    border-bottom: 2px solid black;
    font-size:  12px;
    width: 20%;
}

.Table12 {

    width: 72.6%;   
    padding-top: 0%;
    padding-bottom: 0%;
    table-layout: fixed;
}
.Table12 td{
    border-top:  12px solid black;
    border-left: 12px dashed black;
    border-right: 12px dashed black;
    border-collapse:collapse;
    font-family: GE-BodyCopy,sans-serif;
    font-style: normal;
       font-size:  12px;
    padding-left: .3px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-bottom:  12px solid black;
    vertical-align: top;
    align-content:center;
    height: 10px;
}


.titletop{
    position: relative;
    top: 5px;
    left: 5px;
} 


.WittshindiBox{
    border: 1px solid black;
    height: 25px;
    width: 50px;
 
}


.WittsReportHeadersleftHindi{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  14px;
    padding: 0;
    white-space: nowrap;
}

.WittsReportHeadersrightHindi{
    font-family: GE-BodyCopy,sans-serif;
    font: GE-BodyCopy,sans-serif;
    font-size:  14px;
    padding: 0;
    white-space: nowrap;
}
.borderline1WItts3hindi {
    bottom:none;
    width: 20%;
    height: 2px;
    /* margin: 5px 0 0 ; */
    background-color: black;
  }