



.w-input {
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: inline-block;
}

.w-input label {
    font-family: "GE-BodyCopy",sans-serif;
    box-sizing: border-box;
    /* background: white; */
    padding: 3px;
    /* font-weight: bold; */
    font-size: 14px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: bottom left;
    color: var(--txt-color);
    position: absolute;
    top: 7px;
    left: 7px;
    cursor: text;
}

.w-input input,
.w-input textarea {
    border: 1px solid var(--col-ddd);
    display: block;
    padding: 8px;
    border-radius: 3px;
    box-sizing: border-box;
    height: 45px;
}

.w-input.fill {
    display: block;
    width: 100%;
}

.w-input input:focus,
.w-input textarea:focus {
    outline: 0;
    border-color: var(--godrej-purple);
}

.required-field::after {
    content: '*';
    position: absolute;
    color: var(--godrej-red-alt)
}

.w-input input:focus+label,
.w-input input:not(:placeholder-shown)+label,
.w-input textarea:focus+label,
.w-input textarea:not(:placeholder-shown)+label {
    transform: translateX(-4px) translateY(-15px);
    font-size: 12px;
    color: var(--col-707070);
    background-color: white;
}

.row_height {
    position: relative;
    bottom: 10px;
}

.modal_heading {
    height: 60px;
}
/* 
.modal_title {
    font: 16px;
} */

/* .toast-position {
    height: 10px;
}

.allexport {
    cursor: pointer;
} */

.pic_upload {
    display: flex;
    top: 103px;
    left: 217px;
    width: 576px;
    height: 90px;
    /* UI Properties */
    /* background: var(--unnamed-color-f4f4ff) 0% 0% no-repeat padding-box; */
    background: var(--table-bg);
    border: 1px dashed var(--godrej-purple);
    opacity: 1;
}

.pic_upload_deviation {
    display: grid;
    top: 103px;
    left: 317px;
    width: 876px;
    /* height: 140px; */
    /* UI Properties */
    /* background: var(--unnamed-color-f4f4ff) 0% 0% no-repeat padding-box; */
    background: var(--table-bg) 0% 0% no-repeat padding-box;
    border: 1px dashed var(--godrej-purple);
    opacity: 1;
    text-align: center;
    border-radius: 4px;
}

.userpic_upload {
    display: flex;
    /* top: 103px;
        left: 217px;
        width: 576px;
        height: 90px; */
    top: 462px;
    left: 219px;
    width: 575px;
    height: 68px;
    /* UI Properties */
    background: var(--table-bg);
    /* background: #F4F4FF 0% 0% no-repeat padding-box; */
    border: 1px dashed var(--godrej-purple);
    opacity: 1;
}

.row {
    padding-left: 0px;
}

.form-switch-input1 {
    position: relative;
    left: 300px;
}



.userpic_text_blue {
    position: relative;
    top: 10px;
    left: 140px;
    height: 16px;
    /* UI Properties */
    text-align: center;
    font: normal normal medium 14px/16px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--godrej-purple);
    cursor: pointer;
}

.userpic_text_black {
    position: relative;
    top: 10px;
    left: 140px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal medium 14px/16px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--txt-color);
    cursor: pointer;
}


/* .pic_text {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;

    text-align: center;
    font: normal normal medium 14px/16px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--godrej-purple);
    cursor: pointer;
} */

.pic_text_blue {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal medium 14px/16px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--godrej-purple);
    cursor: pointer;
}

.pic_text_black {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal medium 14px/16px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--txt-color);
    cursor: pointer;
}

.pic_text_black_deviation {
    color: var(--txt-color);
    cursor: pointer;
}

.pic_text_blue_deviation {
    color: var(--godrej-purple);
    cursor: pointer;
}

.pic_text1 {
    position: relative;
    top: 10px;
    left: 150px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--col-707070);
    opacity: 1;
}
/* .file_text1 {
    position: relative;
    top: 10px;
    left: 90px;
    height: 16px;

    text-align: left;
    font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--col-707070);
    opacity: 1;
} */

.pic_text_weld {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--txt-color);
    opacity: 1;
}

.pic_text_weld_deviation {
    font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
    color: var(--txt-color);
    opacity: 1;
}

/* .text_box {
    top: 120px;
    left: 332px;
    width: 300px;
    height: 56px;

    opacity: 1;
} */
/* 
.pic_text2 {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;

    text-align: left;
    font: normal normal normal 12px/14px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--col-707070);
    opacity: 1;
} */

.pic_text_optional {
    position: relative;
    top: 10px;
    left: 80px;
    height: 16px;
    /* UI Properties */
    /* text-align: left;
    font: italic normal normal 12px/14px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--txt-color);
    opacity: 1;
}
 

.imgtext {
    position: relative;
    top: 25px;
    right: 90px;
    height: 16px;
    width: 25%;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 10px/12px "GE-BodyCopy",sans-serif;
    letter-spacing: 0px;
    color: var(--col-707070);
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.circle {
    position: relative;
    top: 12px;
    left: 30px;
    width: 60px;
    height: 60px;
    /* UI Properties */
    background: var(--table-bg) 0% 0% no-repeat padding-box;
    border: 1px solid var(--godrej-purple);
    opacity: 1;
    border-radius: 50px;
}

.imgname {
    position: relative;
    top: 30px;
    left: 30px;
    width: 60px;
    height: 60px;
    /* UI Properties */
    opacity: 1;

}


.dropdownbox {
    width: 77%;
    height: 1%;
    font-family: "GE-BodyCopy",sans-serif;
    font-size: small;
}

.inputext {
    font-family: "GE-BodyCopy",sans-serif;
    font-size: small;
    
}

.inspectiondropdown {
    font-family: "GE-BodyCopy",sans-serif;
    width: 78%;
    height: 44px;
    font-weight: bold;
    color: var(--col-707070);
}

@media screen and (max-width: 768px) {
    .pic_upload_deviation {
        display: grid;
        /* height: 120px; */
        /* background: var(--unnamed-color-f4f4ff) 0% 0% no-repeat padding-box; */
        background: var(--table-bg) 0% 0% no-repeat padding-box;
        border: 1px dashed var(--godrej-purple);
        opacity: 1;
        text-align: center;
        width: auto;
        border-radius: 4px;
    }
}